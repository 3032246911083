export const shuffle = (textToScramble: string): string => {
  var newArr: string[] = [];
  var neww = "";

  textToScramble.split("").map(() => {
    var hash = Math.floor(Math.random() * textToScramble.length);
    neww += textToScramble[hash];
    textToScramble = textToScramble.replace(textToScramble.charAt(hash), "");

    newArr.push(neww);
    neww = "";
  });

  return newArr.join("").toLowerCase();
};
