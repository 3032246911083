import {
  ISchool,
  ISchoolEducationsData,
  ISchoolResponse,
  ISchools,
} from "../../../models/ISchoolInterface";
import {
  ISchoolAddPhoneAndSchool,
  ISchoolAddPhoneAndSchoolResponse,
} from "../../../models/school/ISchoolAddPhoneAndSchool";
import { BLANK_IDATA } from "../general/blankGeneral";
import { BLANK_IUSER_DETAILS } from "../general/blankUser_OLD";
import { BLANK_IEDUCATION_RESPONSE } from "./blankEducation";

// #####################################################################
// ISchool, ISchoolResponse
export const BLANK_ISCHOOL: ISchool = {
  name: "",
  url: "",
  courses: [],
};
// ------ THIS IS NOT NEEDED. IDENTICAL TO IDATA ------
export const BLANK_ISCHOOLS: ISchools = BLANK_IDATA;
// ------ THIS IS NOT NEEDED. IDENTICAL TO IDATA ------
export const BLANK_ISCHOOL_RESPONSE: ISchoolResponse = BLANK_IDATA;
// #####################################################################

// #####################################################################
export const BLANK_ISCHOOL_EDUCATIONS_DATA: ISchoolEducationsData = {
  education: BLANK_IEDUCATION_RESPONSE,
  start: "",
  end: "",
  id: -1,
  school: BLANK_ISCHOOL_RESPONSE,
  url: "",
};
// #####################################################################

// #####################################################################
export const BLANK_ISCHOOL_ADD_PHONE_AND_PHONE: ISchoolAddPhoneAndSchool = {
  school: BLANK_IDATA,
  phonenumber: "",
};
export const BLANK_ISCHOOL_ADD_PHONE_AND_SCHOOL_RESPONSE: ISchoolAddPhoneAndSchoolResponse =
  {
    id: 0,
    school: BLANK_IDATA,
    user: BLANK_IUSER_DETAILS,
  };
// #####################################################################
