import { IIntern } from "../models_NEW_STRUCTURE/general/IIntern";
import IAddStudentToProgram from "../models_NEW_STRUCTURE/ServiceResponses/Education/IAddStudentToProgram";
import ISchoolEducationProgram from "../models_NEW_STRUCTURE/ServiceResponses/Education/ISchoolEducationProgram";
import ISchoolEducationProgramNew from "../models_NEW_STRUCTURE/ServiceResponses/Education/ISchoolEducationProgramNew";
import ISchoolEducationProgramNewFull from "../models_NEW_STRUCTURE/ServiceResponses/Education/ISchoolEducationProgramNewFull";

import ServiceResponse from "../models_NEW_STRUCTURE/ServiceResponses/ServiceResponse";
import { Delete, get, post, put } from "../services/ServicesBase";

const SCHOOL_EDUCATION_ROUTE = "school/education";

// ========================================  GET METHODS  ========================================
//GET   /school/education   //gets educational program data
export const getSchoolEducation = async (signal?: AbortSignal) => {
  const res = await get<ServiceResponse<ISchoolEducationProgram[]>>(
    SCHOOL_EDUCATION_ROUTE,
    { signal: signal }
  );
  return res.data;
};


export const getSchoolEducationFull = async (signal?: AbortSignal) => {
  const res = await get<ServiceResponse<ISchoolEducationProgramNewFull[]>>(
    SCHOOL_EDUCATION_ROUTE,
    { signal: signal }
  );
  return res.data;
};

export const getStudentInProgram = async (id: Number, signal?: AbortSignal) => {
  const route = `${SCHOOL_EDUCATION_ROUTE}/program/students/${id}`;
  const res = await get<ServiceResponse<IIntern[]>>(route);
  return res.data;}




// ========================================  POST METHODS  ========================================
//POST   /school/education   //posts new education to school obj
export const postSchoolEducation = async (
  data: ISchoolEducationProgramNew,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<ISchoolEducationProgramNew>>(
    SCHOOL_EDUCATION_ROUTE,
    data,
    { signal: signal }
  );
  return res.data;
};

export const addStudentToEducation = async (data: IAddStudentToProgram) => {
  const route = `${SCHOOL_EDUCATION_ROUTE}/addStudent`;
  const res = await put<ServiceResponse<string>>(route, data);
  return res.data;
};
// ========================================  PUT METHODS  ========================================
//PUT   /school/education/:id   //updates education
export const putSchoolEducationId = async (
  id: number,
  data: ISchoolEducationProgram,
  signal?: AbortSignal
) => {
  const res = await put<ServiceResponse<ISchoolEducationProgram>>(
    `${SCHOOL_EDUCATION_ROUTE}/${id}`,
    data,
    { signal: signal }
  );
  return res.data;
};

// ========================================  DELETE METHODS  ========================================
//DELETE   /school/education/:id   //deletes education
export const deleteSchoolEducationId = async (
  id: number,
  signal?: AbortSignal
) => {
  const res = await Delete<ServiceResponse<ISchoolEducationProgram>>(
    `${SCHOOL_EDUCATION_ROUTE}/${id}`,
    { signal: signal }
  );
  return res.data;
};
