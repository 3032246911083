import { useEffect } from "react";
import { createRegisterCheckoutSession } from "../../../services/payment.service";
import "./AddRegisterPayment.scss";

export const AddRegisterPayment = () => {
  useEffect(() => {
    let isMounted = true;
    const createSession = async () => {
      let response = await createRegisterCheckoutSession("subscription");
      if (isMounted) {
        if (response.success) {
          window.location.href = response.data;
        }
      }
    };

    createSession();

    return () => {
      isMounted = false;
    };
  });

  return (
    <div className="payment-container">
      <div></div>
    </div>
  );
};
