export interface IRoutes {
  route: string;
  name: string;
  id: number;
}

export interface IUserNavbarData {
  userType: string;
  routes: IRoutes[];
}

export const navbarDataList: IUserNavbarData[] = [
  {
    userType: "",
    routes: [
      { route: "/company/search", name: "Personer", id: 1 },
      { route: "/student/search", name: "Företag", id: 2 },
      { route: "/register", name: "Gå med nu", id: 3 },
      { route: "/login", name: "Logga in", id: 4 },
    ],
  },
  {
    userType: "Student",
    routes: [
      { route: "/student/search", name: "Sök företag", id: 1 },
      // { route: "/favorites/booked", name: "Bekräftad", id: 8 },
      { route: "/favorites/", name: "Favoriter", id: 7 },
      { route: "/favorites/myapplications", name: "Ansökningar", id: 9 },
      { route: "/messages", name: "Meddelanden", id: 2 },
      { route: "/student/profile", name: "Profil", id: 3 },
      // { route: "/addinternperiod", name: "Mina annonser", id: 4 },
      // { route: "/help", name: "Support", id: 5 },
    ],
  },
  {
    userType: "Company",
    routes: [
      { route: "/company/search", name: "Sök praktikanter", id: 1 },
      // { route: "/favorites/booked", name: "Bekräftad", id: 7 },
      { route: "/favorites/showninterest", name: "Visat intresse", id: 6 },
      { route: "/favorites/candidates", name: "Favoriter", id: 5 },
      { route: "/company/myjobs", name: "Ansökningar", id: 10 },
      { route: "/messages", name: "Meddelanden", id: 2 },
      { route: "/company/profile", name: "Profil", id: 3 },
      // { route: "/help", name: "Hjälp", id: 5 },
    ],
  },
  {
    userType: "School",
    routes: [
      // { route: "/student/search", name: "Sök ", id: 1 },
      // { route: "/favorites", name: "Sparade", id: 7 },
      // { route: '/messages', name: 'Meddelanden', id: 2 },
      { route: "/schooluser/profile", name: "Min profil", id: 3 },
      { route: "/schooluser/searchstudents", name: "Sök studenter", id: 4 },
      { route: "/schooluser/studentOverview", name: "Mina studenter", id: 5 },
      // { route: "/favorites/candidates", name: "Favoriter", id: 6 },
      // { route: "/help", name: "Support", id: 5 },
    ],
  },
  {
    userType: "",
    routes: [],
  },
];
