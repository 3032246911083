import { ICompany } from "../../../models_NEW_STRUCTURE/general/ICompany";
import IData from "../../../models_NEW_STRUCTURE/general/IData";
import { IIntern } from "../../../models_NEW_STRUCTURE/general/IIntern";
import IPaginatedData from "../../../models_NEW_STRUCTURE/general/IPaginatedData";

export const BLANK_IPAGINATED_DATA_IDATA_LIST: IPaginatedData<IData[]> = {
  pageNumber: 0,
  pageSize: 0,
  firstPage: "",
  lastPage: "",
  nextPage: "",
  previousPage: "",
  totalPages: 0,
  totalRecords: 0,
  data: [],
};

export const BLANK_IPAGINATED_DATA_IINTERN_LIST: IPaginatedData<IIntern[]> = {
  ...BLANK_IPAGINATED_DATA_IDATA_LIST,
  data: [],
};
export const BLANK_IPAGINATED_DATA_ICOMPANY_LIST: IPaginatedData<ICompany[]> = {
  ...BLANK_IPAGINATED_DATA_IDATA_LIST,
  data: [],
};
