import { useEffect, useState } from "react";
import { RiCheckLine, RiDeleteBin6Line, RiPencilLine } from "react-icons/ri";
import { isCancel } from "../../../../helpers/AbortHelpers";
import {
  hasOnlyNumbers,
  hasProtocol,
  isEmpty,
  isNameWithWhitespace,
} from "../../../../helpers/StringValidations";
import useObjectState from "../../../../hooks/useObjectState/useObjectState";
import ISchoolEducationProgram from "../../../../models_NEW_STRUCTURE/ServiceResponses/Education/ISchoolEducationProgram";
import {
  deleteSchoolEducationId,
  putSchoolEducationId,
} from "../../../../services_NEW_STRUCTURE/SchoolEducation.service";
import "./educationalProgramCard.scss";

interface IInputValidity {
  name: boolean;
  code: boolean;
  year: boolean;
  url: boolean;
}

interface IInputStates {
  education: {
    id: number;
    name: string;
  };
  code: string;
  url: string;
  year: string;
}

const BLANK_INPUT_VALIDITY: IInputValidity = {
  name: true,
  code: true,
  year: true,
  url: true,
};

// COMPONENT PROPS
interface IProps {
  fetchEduPrograms: (signal: AbortSignal) => void;
  inputsDisabled: boolean;
  programCard: ISchoolEducationProgram;
}

// ABORTCONTROLLERS
let deleteEducationalProgramController: AbortController;
let editEducationalProgramController: AbortController;

// COMPONENT
export const EducationalProgramCard = ({
  fetchEduPrograms,
  inputsDisabled,
  programCard,
}: IProps) => {
  // STATE FOR ERROR HANDLING
  const [error, setError] = useState<string>("");

  // STATE FOR EDIT PROGRAM
  const [editProgram, setEditProgram] = useState<boolean>(false);

  // STATE FOR INPUT FIELDS
  const [inputFields, setInputFields] = useObjectState<IInputStates>({
    education: {
      id: programCard.education.id,
      name: programCard.education.name,
    },
    code: programCard.code,
    year: programCard.year,
    url: programCard.url,
  });

  // VALIDATION STATES
  const [validStates, setValidStates] =
    useObjectState<IInputValidity>(BLANK_INPUT_VALIDITY);

  const [liveValidate, setLiveValidate] = useState(false);

  const [validationMsg, setValidationMsg] = useObjectState<IInputStates>({
    education: {
      id: -1,
      name: "Du måste ange en giltig programtitel, endast text.",
    },
    code: "Du måste ange en programkod.",
    year: "Du måste ange startår för utbildningen.",
    url: "Du måste ange en giltig URL för din utbildning.",
  });

  // CHECK TO SEE IF INPUT IS VALID
  const isFormValid = (): boolean => {
    let isValid = true;
    const validity: IInputValidity = { ...BLANK_INPUT_VALIDITY };
    const errorMsgs: IInputStates = { ...validationMsg };

    // PROGRAM NAME CHECK
    if (!inputFields.education.name) {
      isValid = false;
      validity.name = false;
    }

    // PROGRAM CODE CHECK
    if (isEmpty(inputFields.code)) {
      isValid = false;
      validity.code = false;
    }

    // PROGRAM YEAR CHECK
    if (!hasOnlyNumbers(inputFields.year) || inputFields.year.length !== 4) {
      isValid = false;
      validity.year = false;
    }

    // URL CHECK
    if (!hasProtocol(inputFields.url)) {
      isValid = false;
      validity.url = false;
    }

    // UPDATE VALIDITY STATES
    setValidStates({
      ...validity,
    });

    // IF isValid = false, WE HAVE INVALID INPUTS
    if (!isValid) {
      setLiveValidate(true);
      setValidationMsg({
        ...errorMsgs,
      });

      return false;
    } else return true;
  };

  // USEEFFECT FOR LIVE VALIDATION
  useEffect(() => {
    if (liveValidate) isFormValid();
  }, [inputFields]);

  // DELETE PROGRAM
  const handleDeleteProgram = async (id: number) => {
    if (deleteEducationalProgramController)
      deleteEducationalProgramController.abort();
    deleteEducationalProgramController = new AbortController();

    try {
      const res = await deleteSchoolEducationId(
        id,
        deleteEducationalProgramController.signal
      );
      if (res.success) {
        fetchEduPrograms(deleteEducationalProgramController.signal);
      } else setError(res.error);
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
  };

  // UPDATE PROGRAM
  const handleEditProgram = async () => {
    if (editEducationalProgramController)
      editEducationalProgramController.abort();
    editEducationalProgramController = new AbortController();

    try {
      if (isFormValid()) {
        const updatedData: ISchoolEducationProgram = {
          ...inputFields,
          education: { ...inputFields.education, id: programCard.education.id },
        };
        const res = await putSchoolEducationId(
          updatedData.education.id,
          updatedData,
          editEducationalProgramController.signal
        );
        if (res.success) {
          fetchEduPrograms(editEducationalProgramController.signal);
          setEditProgram(false);
        } else setError(res.error);
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
  };

  // GENERIC UPDATE INPUTS FOR PROGRAM
  const updateInputs = (name: string, value: string) => {
    setInputFields({ ...inputFields, [name]: value });
  };

  // HANDLE CLICK FUNCTION FOR EDIT BUTTON
  const handleClick = () => {
    setEditProgram(!editProgram);
    if (isFormValid() && editProgram) {
      handleEditProgram();
    }
  };

  return (
    <>
      <div className="educations-container">
        <div className="educations-container__program-card">
          <h1 className="educations-container__program-card--heading">
            {programCard.education.name}
            {inputsDisabled ? (
              <></>
            ) : (
              <div className="edit-btns-container">
                <button
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {!inputsDisabled && !editProgram ? (
                    <RiPencilLine className="icon" />
                  ) : (
                    <RiCheckLine className="icon" />
                  )}
                </button>
                <button
                  onClick={() => {
                    handleDeleteProgram(programCard.education.id);
                  }}
                >
                  <RiDeleteBin6Line className="icon" />
                </button>
              </div>
            )}
          </h1>
          {/* <p className="educations-container__program-card--code">
            Programkod: {programCard.code}
          </p> */}
          <p className="educations-container__program-card--year">
            Startår: {programCard.year}
          </p>
          {/* <div className="educations-container__program-card--url">
            <p>Länk till utbildning: </p>
            <a href={programCard.url} target="_blank" rel="noreferrer noopener">
              {programCard.url}
            </a>
          </div> */}

          {!inputsDisabled && editProgram ? (
            <div className="educations-container__edit-program-form">
              <input
                type="text"
                value={inputFields.education.name}
                onChange={(e) => {
                  updateInputs("name", e.target.value);
                }}
                required={true}
              />
              {!validStates.name ? (
                <span>{validationMsg.education.name}</span>
              ) : (
                ""
              )}
              {/* <input
                type="text"
                value={inputFields.code}
                onChange={(e) => {
                  updateInputs("code", e.target.value);
                }}
                required={true}
              />
              {!validStates.code ? <span>{validationMsg.code}</span> : ""} */}
              <input
                type="text"
                value={inputFields.year}
                onChange={(e) => {
                  updateInputs("year", e.target.value);
                }}
                required={true}
              />
              {!validStates.year ? <span>{validationMsg.year}</span> : ""}
              {/* <input
                type="url"
                value={inputFields.url}
                onChange={(e) => {
                  // ADDS PROTOCOL TO URL IF IT DOESN'T HAVE IT
                  let url = hasProtocol(e.target.value)
                    ? e.target.value
                    : "https://" + e.target.value;
                  updateInputs("url", url);
                }}
                required={true}
              />
              {!validStates.url ? <span>{validationMsg.url}</span> : ""} */}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EducationalProgramCard;
