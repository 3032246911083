import { RiCloseLine } from "react-icons/ri";
import "./popupPremiumAlert.scss";

interface IProps {
  message: string;
  title: string;
  yesBtnText: string;
  noBtnText?: string;
  yesAlertFunction: () => void;
  noAlertFunction?: () => void;
  closeFunction: () => void;
}

const PopupPremiumAlert = ({
  message,
  title,
  yesBtnText,
  noBtnText,
  yesAlertFunction,
  noAlertFunction,
  closeFunction,
}: IProps) => {
  return (
    <div id="popup-alert">
      <div className="alert-box">
        <button className="close-btn" onClick={closeFunction}>
          <RiCloseLine className="close-btn__icon" />
        </button>
        <h3 className="alert-title">{title}</h3>
        <p className="alert-message">{message}</p>
        <section className="cta-btns">
          {noBtnText && (
            <>
              <button className="alert-no-btn" onClick={noAlertFunction}>
                {noBtnText}
              </button>
            </>
          )}
          <button className="alert-yes-btn" onClick={yesAlertFunction}>
            {yesBtnText}
          </button>
        </section>
      </div>
    </div>
  );
};

export default PopupPremiumAlert;
