import { ICompany } from "../../models_NEW_STRUCTURE/general/ICompany";
import IFile from "../../models_NEW_STRUCTURE/general/IFile";
import { IIntern } from "../../models_NEW_STRUCTURE/general/IIntern";
import IUser from "../../models_NEW_STRUCTURE/general/IUser";
import IBasicResponse from "../../models_NEW_STRUCTURE/ServiceResponses/IBasicResponse";
import ServiceResponse from "../../models_NEW_STRUCTURE/ServiceResponses/ServiceResponse";
import { Delete, post, get } from "../../services/ServicesBase";
import IData from "../../models/general/IData";
import { IUserDetails2 } from "../../models/users/IUserDetails";
import { AxiosResponse } from "axios";

// ##### ROUTES #####
const COMPANY_PROFILE_ROUTE = "company/Profile";
const STUDENT_PROFILE = "company/internship/internshipApplied";
// ========================================  GET METHODS  ========================================
// GET    /company/Profile
export const getCompanyProfile = async (signal?: AbortSignal) => {
  const res = await get<ServiceResponse<ICompany>>(COMPANY_PROFILE_ROUTE, {
    signal: signal,
  });
  return res.data;
};

// GET    /company/Profile/:id
export const getCompanyProfileById = async (
  id: string,
  userRole?: string,
  signal?: AbortSignal
) => {
  try {
    let res: AxiosResponse<ServiceResponse<ICompany>>;

    if (!userRole) {
      res = await get<ServiceResponse<ICompany>>(
        `${COMPANY_PROFILE_ROUTE}/${id}`,
        {
          signal: signal,
        }
      );
    } else {
      res = await get<ServiceResponse<ICompany>>(
        `${COMPANY_PROFILE_ROUTE}/${id}?userRole=${encodeURIComponent(
          userRole!
        )}`,
        {
          signal: signal,
        }
      );
    }
    return res.data;
  } catch (e) {
    console.log("Error:", e);
  }
};

// GET    /company/Profile/user/:id
export const getCompanyProfileUserById = async (
  id: string,
  signal?: AbortSignal
) => {
  const res = await get<ServiceResponse<ICompany>>(
    `${COMPANY_PROFILE_ROUTE}/user/${id}`,
    {
      signal: signal,
    }
  );
  return res.data;
};

// Get  /company/profile/companyUser/:id
export const getCompanyUserByCompanyId = async (
  id: string,
  signal?: AbortSignal
) => {
  const res = await get<ServiceResponse<IUserDetails2>>(
    `${COMPANY_PROFILE_ROUTE}/companyUser/${id}`,
    {
      signal: signal,
    }
  );
  return res.data;
};

// ========================================  POST METHODS  ========================================
// POST   /company/Profile/create
export const postCompanyProfileCreate = async (
  newCompany: ICompany,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<ICompany>>(
    `${COMPANY_PROFILE_ROUTE}/create`,
    newCompany,
    {
      signal: signal,
    }
  );
  return res.data;
};
// POST   /company/Profile/update
export const postCompanyProfileUpdate = async (
  changes: {
    name: string;
    description: string;
    url: string;
    location: string;
    region: string;
    adress: string;
    zip: string;
    city: string;
    imageUrl: string;
    organisationNumber: string;
    allowMessagesFromStudents: boolean;
    isSubscription: boolean;
    IndustryTags?: string[];
  },
  signal?: AbortSignal
): Promise<ServiceResponse<ICompany>> => {
  const res = await post<ServiceResponse<ICompany>>(
    `${COMPANY_PROFILE_ROUTE}/update`,
    changes,
    {
      signal: signal,
    }
  );
  return res.data;
};
// POST   /company/Profile/addlogo
export const postCompanyProfileAddlogo = async (
  file: IFile,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    `${COMPANY_PROFILE_ROUTE}/addlogo`,
    file,
    {
      signal: signal,
    }
  );
  return res.data;
};
// POST   /company/Profile/paymentinfo
export const postCompanyProfilePaymentInfo = async (
  paymentInfo: {
    organisationNumber: string;
    adress: string;
    zip: string;
    city: string;
  },
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    `${COMPANY_PROFILE_ROUTE}/paymentinfo`,
    paymentInfo,
    {
      signal: signal,
    }
  );
  return res.data;
};
// POST   /company/Profile/contactinfo
export const postCompanyProfileContactInfo = async (
  contactInfo: {
    title: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
  },
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    `${COMPANY_PROFILE_ROUTE}/contactinfo`,
    contactInfo,
    {
      signal: signal,
    }
  );
  return res.data;
};
// POST   /company/Profile/connecttocompany/:companyId
export const postCompanyProfileConnectToCompanyByCompanyId = async (
  companyId: string,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IUser>>(
    `${COMPANY_PROFILE_ROUTE}/connecttocompany/${companyId}`,
    null,
    {
      signal: signal,
    }
  );
  return res.data;
};
// POST   /company/Profile/connecttonewcompany
export const postCompanyProfileConnectToNewCompany = async (
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IUser>>(
    `${COMPANY_PROFILE_ROUTE}/connecttonewcompany`,
    null,
    {
      signal: signal,
    }
  );
  return res.data;
};

export interface Tags {
  industryTags: string[];
}

export const postTags = async (tagsinfo: Tags) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    `${COMPANY_PROFILE_ROUTE}/companyInDtags`,
    tagsinfo
  );
  return res.data;
};

export const UpdateIndustryTags = async (tagsinfo: Tags) => {
  const res = await post<ServiceResponse<ICompany>>(
    `${COMPANY_PROFILE_ROUTE}/updateIndustryTags`,
    tagsinfo
  );
  return res.data;
};

// ========================================  DELETE METHODS  ========================================
// DELETE  /company/Profile/image
export const deleteCompanyProfileImage = async (signal?: AbortSignal) => {
  const res = await Delete<ServiceResponse<IBasicResponse>>(
    `${COMPANY_PROFILE_ROUTE}/image`,
    {
      signal: signal,
    }
  );
  return res.data;
};

export const deleteCompanyAccount = async (signal?: AbortSignal) => {
  const res = await Delete<ServiceResponse<IBasicResponse>>(
    `${COMPANY_PROFILE_ROUTE}/deleteaccount`, {
      signal: signal, 
    }
  );
};

/* 
// GET  /students/Profile
export const getStudentProfileT = async (
  id: string) => {
  
    const STUDENT_PROFILE = `company/Internship/internshipApplied/${id}`

  const res = await get<ServiceResponse<IIntern>>(STUDENT_PROFILE )
  return res.data;
};
 */
// GET  /students/Profile/getstudentbyid/:id
export const getStudentProfileT = async () => {
  const route = `company/Internship/internshipApplied`;
  const res = await get<ServiceResponse<IIntern[]>>(route);
  return res.data;
};
