import "./inputWithValidationMsg.scss";
import React from "react";

interface IProps {
  // INPUT TAG
  placeholder: string;
  id: string;
  type: string;
  name?: string;
  label?: string;
  // INPUT STATE
  value: string;
  onChange: (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  // VALID STATE
  isValidState: boolean;
  validationMsg: string;
  tipsText?: string;
  // TAB INDEX
  tabIndex?: number;
  // IF type="date", DEFINE MIN / MAX
  min?: string;
  max?: string;
  // DISABLED
  disabled?: boolean;
  discription?: string;
  classname?: string;
  required?: boolean;
}

const InputWithValidationMsg = (props: IProps) => {
  // props.classname = `input-with-validation-msg ${
  //  props.isValidState ? "" : "invalid"}`
  return (
    <div
      className={
        props.classname
          ? props.classname
          : `input-with-validation-msg ${props.isValidState ? "" : "invalid"}`
      }
    >
      {props.label && (
        <label className="input-label" htmlFor={props.id}>
          {props.label} {props.required && <p className="required-mark"> *</p>}
        </label>
      )}

      <div className="input-wrapper">
        {props.type === "textarea" ? (
          <textarea
            className="input-textarea"
            tabIndex={props.tabIndex ? props.tabIndex : undefined}
            id={props.id}
            name={props.name ? props.name : ""}
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.disabled ? props.disabled : false}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              e.preventDefault();
              props.onChange(e);
            }}
          />
        ) : (
          <input
            className="input-regular"
            tabIndex={props.tabIndex ? props.tabIndex : undefined}
            id={props.id}
            name={props.name ? props.name : ""}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.disabled ? props.disabled : false}
            min={props.min}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault();
              props.onChange(e);
            }}
          />
        )}
      </div>
      {props.tipsText && <p className="input-tips-text">{props.tipsText}</p>}
      {props.discription && <p className="description">{props.discription}</p>}

      <span
        className={`validation-msg ${
          !props.isValidState ? "show invalid" : "hide"
        }`}
      >
        {props.validationMsg}
      </span>
    </div>
  );
};

export default InputWithValidationMsg;
