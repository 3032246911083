import "./spinner.scss";

export type ISpinnerSize =
  | "small"
  | "medium"
  | "large"
  | "x-large"
  | "xx-large";
interface IProps {
  size: ISpinnerSize;
  className?: string;
}

const Spinner = (props: IProps) => {
  return (
    <div
      className={`spinner ${props.size} ${
        props.className ? props.className : ""
      }`}
    />
  );
};

export default Spinner;
