import { ChangeEvent, useState } from "react";
import { sendResetPasswordLink } from "../services/account.service";
import "./ResetPassword.scss";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const sendResetLinkClick = async () => {
    let response = await sendResetPasswordLink({ email });

    if (response.success) {
      setMsg("Ett mail har skickats till dig med en återställningslänk");
    } else {
      setMsg(response.error);
    }
  };

  return (
    <main id="reset-password-page">
      <div className="reset-container">
        <label htmlFor="email">E-postadress</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
        />
        <button type="button" onClick={sendResetLinkClick}>
          Skicka återställningslänk
        </button>
        {msg}
      </div>
    </main>
  );
};
