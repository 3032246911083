import { get, put } from "../ServicesBase";
import { StudentResponse } from "../../models/intern/StudentResponse";
import { ServiceResponse } from "../../models/serviceResponses/serviceResponse";
import { AxiosRequestConfig } from "axios";

export const getStudentAccountInfo = async (signal?: AxiosRequestConfig) => {
  const route = "students/profile";
  const res = await get<ServiceResponse<StudentResponse>>(route, signal);
  return res;
};

export const getStudentProfileById = async (
  id: string,
  signal: AbortSignal
) => {
  const route = `students/Profile/getstudentbyid/${id}`;

  const res = await get<ServiceResponse<StudentResponse>>(route, {
    signal: signal,
  });
  return res.data;
};

export const getStudentProfileByUserId = async (
  id: string,
  signal?: AbortSignal
) => {
  const route = `students/Profile/getstudentbyuserid/${id}`;
  const res = await get<ServiceResponse<StudentResponse>>(route, { signal });
  return res.data;
};

export const unlockstudent = async (id: string) => {
  const route = `students/Profile/unlockstudent/${id}`;

  const res = await put<ServiceResponse<StudentResponse>>(route, id);
  return res.data;
};

export const lockstudent = async (id: string) => {
  const route = `students/Profile/lockstudent/${id}`;

  const res = await put<ServiceResponse<StudentResponse>>(route, id);
  return res.data;
};
