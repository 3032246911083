import "./schoolUserProfileCreated.scss";

import { RiArrowRightLine, RiAddLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

// interface IProps {}

export function SchoolUserProfileCreated() {
  const navigate = useNavigate();
  const goToAddEducation = () => {
    navigate("/schooluser/profile");
  };

  const goToSearchStudents = () => {
    navigate("/schooluser/searchstudents");
  };

  return (
    <div className="school-user-profile-created">
      <section className="content-section">
        <header>
          <h1>Välkommen till Hopen!</h1>
        </header>
        <h2>
          Din profil är skapad. Nu kan du hitta och lägga till studenter som
          sparade.
        </h2>
        {/* <button type="button" onClick={goToAddEducation}>
          Gå till min profil <RiArrowRightLine className="right-icon" />
        </button> */}
        <button type="button" onClick={goToSearchStudents}>
          Sök studenter <RiSearchLine className="right-icon" />
        </button>
      </section>
    </div>
  );
}
