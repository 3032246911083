import IBasicResponse from "../models/general/IBasicResponse";
import IFile from "../models/general/IFile";
import IVideoData from "../models/general/IVideoData";

import { ServiceResponse } from "../models/serviceResponses/serviceResponse";
import { post, Delete, DeleteFromZiggeo } from "../services/ServicesBase";

// ####### ROUTES #######
const POST_UPLOAD_DOCUMENT = "Files/uploaddocument";
const POST_UPLOAD_IMAGE = "Files/uploadimage";
const POST_UPLOAD_VIDEO = "students/profile/video/add";
const POST_UPLOAD_COMPANY_VIDEO = "company/profile/video/add";

// ========================================  POST METHODS  ========================================
// POST     /Files/uploaddocument
export const postFilesUploadDocument = async (
  documentData: IFile,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    POST_UPLOAD_DOCUMENT,
    documentData,
    {
      signal: signal,
    }
  );
  return res.data;
};

// POST     /Files/uploadimage
export const postFilesUploadImage = async (
  imageData: IFile,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    POST_UPLOAD_IMAGE,
    imageData,
    {
      signal: signal,
    }
  );
  return res.data;
};

export const addVideo = async (data: IVideoData) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    POST_UPLOAD_VIDEO,
    data
  );
  return res.data;
};

export const deleteVideo = async (id: number) => {
  const res = await Delete<ServiceResponse<IBasicResponse>>(
    `students/profile/video/delete/${id}`
  );
  return res.data;
};
export const addCompanyVideo = async (data: IVideoData) => {
  const res = await post<ServiceResponse<IBasicResponse>>(
    POST_UPLOAD_COMPANY_VIDEO,
    data
  );
  return res.data;
};
export const deleteCompanyVideo = async (id: number) => {
  const res = await Delete<ServiceResponse<IBasicResponse>>(
    `company/profile/video/delete/${id}`
  );
  return res.data;
};
export const deleteVideoFromZiggeo = async (
  apiToken: string,
  videoToken: string
) => {
  const res = await DeleteFromZiggeo<ServiceResponse<IBasicResponse>>(
    `${apiToken}/videos/${videoToken}`
  );
  return res.data;
};

export const startVideoProcessing = async (videoToken: string) => {
  await post(`${process.env.REACT_APP_VIDEO_PROCESSING_URL}`, {
    token: videoToken,
  });
};
