import { ServiceResponse } from "../../models/serviceResponses/serviceResponse";
import { get } from "../ServicesBase";



export interface IUserInfoResponse {
    name: string;
    id: number;
    internPeriod: any;
}

export const getStudentInfoOnBookCompleted = async (sessionId: string) => {
    const route = `company/payment/getstudentfromsession?session=${sessionId}`;
    const res = await get<ServiceResponse<IUserInfoResponse>>(route);

    return res.data;
}