import "./AddNewProgram.scss";
import { useState, useCallback, useEffect } from "react";
import IData from "../../models_NEW_STRUCTURE/general/IData";
import { getSchoolEducationFull } from "../../services_NEW_STRUCTURE/SchoolEducation.service";
import { isCancel } from "../../helpers/AbortHelpers";
import Dropdown from "../../components/general/dropdown/Dropdown";
import { BLANK_IDATA } from "../../constants/blankData/general/blankGeneral";
import { useParams } from "react-router-dom";
import { StudentResponse } from "../../models/intern/StudentResponse";
import { getStudentProfileById } from "../../services/students/getStudentData";
import IAddStudentToProgram from "../../models_NEW_STRUCTURE/ServiceResponses/Education/IAddStudentToProgram";
import { addStudentToEducation } from "../../services_NEW_STRUCTURE/SchoolEducation.service";
import { IEducation } from "../../models/IStudentInfomrationInterface";
import Spinner from "../general/spinner/Spinner";

interface IProps {
  title: string;
  yesBtnText: string;
  noBtnText: string;
  yesFunction: () => void;
  noFunction: () => void;
  idFromSearch?: number;
}

export const AddStudentToProgram = ({
  title,
  yesBtnText,
  noBtnText,
  yesFunction,
  noFunction,
  idFromSearch,
}: IProps) => {
  const [program, setProgram] = useState<IEducation[]>([]);
  const [error, setError] = useState<string>("");
  const [chosenprogram, setChosenprogram] = useState<IData>(BLANK_IDATA);
  const [isAdded, setIsadded] = useState(true);
  const errMsg =
    "Misslyckades pga. student befinner sig redan i en utbildning. Kontakta studenten för mer information";

  let fetchEducationalProgramsController: AbortController;

  const params = useParams();
  const [studentAccountInfo, setStudentAccountInfo] = useState<StudentResponse>(
    {
      user: {
        id: "",
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        imageUrl: "",
        type: "",
        lastLoggedIn: "",
        hasUnreadMessages: false,
        isActivated: false,
      },
      id: 0,
      title: "",
      experiences: [],
      internPeriods: [],
      tags: [],
      education: {
        code: "",
        education: { id: 0, name: "" },
        url: "",
        school: { id: 0, name: "" },
        year: "",
      },
      educations: [],
      oneLiner: "",
      about: "",
      isFavourite: false,
    }
  );
  const [addedComplete, setAddedComplete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //fetch programs
  const fetchEduPrograms = useCallback(async (signal?: AbortSignal) => {
    try {
      const res = await getSchoolEducationFull(signal);
      if (res.success) {
        //setPrograms(res.data);
        if (res.data)
          setProgram(res.data.map((p) => p.education as IEducation));
      } else {
        setError(res.error);
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
  }, []);

  const addStudent = async (data: IAddStudentToProgram) => {
    try {
      const res = await addStudentToEducation(data);
      if (res.success) {
        if (isAdded) {
          // noFunction();
          setAddedComplete(true);
        }
      }
      if (!res.success) {
        setIsadded(false);
        setAddedComplete(true);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (fetchEducationalProgramsController)
        fetchEducationalProgramsController.abort();
      fetchEducationalProgramsController = new AbortController();
      fetchEduPrograms(fetchEducationalProgramsController.signal);
    }
    return () => {
      if (fetchEducationalProgramsController) {
        fetchEducationalProgramsController.abort();
      }
      isMounted = false;
    };
  }, []);

  // fetch student info
  useEffect(() => {
    let getOnce = 0;
    const abortController = new AbortController();

    if (studentAccountInfo.user.email === "") {
      const getData = async (id: string) => {
        const res = await getStudentProfileById(id, abortController.signal);

        if (res.success) {
          setStudentAccountInfo(res.data);

          getOnce++;
        }
      };

      if (params.id) {
        let id = params.id;

        if (getOnce === 0) {
          getData(id);
        } else return;
      }
    }

    return () => {
      abortController.abort();
    };
  }, [params.id, studentAccountInfo]);

  return (
    <div id="modal">
      <div className="alert-box">
        {!addedComplete ? (
          <>
            <h3 className="alert-title">{title}</h3>
            <section>
              <div className="student-public__info--menu">
                <Dropdown
                  placeholder="Välj en utbildning"
                  data={program}
                  // updateState={updateEducationState}
                  onSelect={(p) => setChosenprogram(p[0])}
                  startValue={[chosenprogram]}
                />
              </div>
            </section>
            <section className="cta-btns">
              {!isLoading ? (
                <button
                  type="submit"
                  className="alert-yes-btn"
                  onClick={() => {
                    setIsLoading(true);
                    if (idFromSearch) {
                      addStudent({
                        studentId: idFromSearch,
                        educationId: chosenprogram.id,
                      });
                    } else {
                      addStudent({
                        studentId: studentAccountInfo.id,
                        educationId: chosenprogram.id,
                      });
                    }
                  }}
                >
                  {yesBtnText}
                </button>
              ) : (
                <Spinner size="large"></Spinner>
              )}
              <button className="alert-no-btn" onClick={noFunction}>
                {noBtnText}
              </button>
            </section>
          </>
        ) : (
          <>
            {!isAdded ? (
              <span className="err">{errMsg}</span>
            ) : (
              <p className="verify-message">
                Din student har lagts till i din kurs! &#128077;
              </p>
            )}
            <button className="alert-yes-btn" onClick={noFunction}>
              Stäng
            </button>{" "}
          </>
        )}
      </div>
    </div>
  );
};
