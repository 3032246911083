import {
  IChangePassword,
  IRequestPasswordChange,
  IResetPassword,
} from "../../../models/ChangePassword";
import { INewAccountResponse } from "../../../models/INewAccountResponse";
import { IPersonal } from "../../../models/IPersonalInterface";
import { IVerifyEmail } from "../../../models/IVerifyEmail";
import { IAddSkillsData } from "../../../models/users/IAddSkillsData";
import { ICreateExperience } from "../../../models/users/ICreateExperience";
import { ICreateNewPost } from "../../../models/users/ICreateNewPost";
import { IContextValue, IUser } from "../../../models/users/IUser";
import { IUserData } from "../../../models/users/IUserData";
import {
  IUserDetails,
  IUserDetails2,
} from "../../../models/users/IUserDetails";

// #####################################################################
// IUser (Logged in user (I assume))
export const BLANK_IUSER: IUser = {
  username: "",
  loggedIn: false,
  role: "",
  id: "",
  hasUnreadMessages: false,
};
export const BLANK_ICONTEXT_VALUE: IContextValue = {
  user: BLANK_IUSER,
  saveLoggedInUser: (email, role) => {},
  logout: (success) => {},
  getLoggedIn: () => {},
  isAdmin: (token) => new Promise((resolve, reject) => {}),
  loading: false,
  updateReadMessages: (hasUnreadMessages: boolean) => {},
  companySubscription: false,
  getCompanySubscription: () => {},
};
// #####################################################################
// #####################################################################
// BASE USER
export const BLANK_IUSER_DETAILS: IUserDetails = {
  id: "",
  email: "",
  firstname: "",
  lastname: "",
  hasUnreadMessages: false,
  phoneNumber: "",
  imageUrl: "",
  type: "",
  lastLoggedIn: "",
  isActivated: false,
};

export const BLANK_IUSER_DETAILS2: IUserDetails2 = {
  user: {
    id: "",
    email: "",
    firstname: "",
    lastname: "",
    hasUnreadMessages: false,
    phoneNumber: "",
    imageUrl: "",
    type: "",
    lastLoggedIn: "",
  },
};

export const BLANK_COMPANY_DATA = {
  id: "",
  name: "",
  url: "",
  location: "",
  description: "",
  numberOfEmployees: 0,
  imageUrl: "",
  organisationNumber: "",
  adress: "",
  zip: "",
  city: "",
  title: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  internships: [],
  videos: [],
  isFavourite: false,
  allowMessagesFromStudents: false,
};

export const BLANK_IUSER_DATA: IUserData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  type: "",
  password: "",
};

// #####################################################################

// #####################################################################
// IChangePassword, INewAccountResponse, IVerifyEmail
export const BLANK_ICHANGE_PASSWORD: IChangePassword = {
  oldPassword: "",
  newPassword: "",
  newPasswordAgain: "",
};
export const BLANK_IREQUEST_PASSWORD_CHANGE: IRequestPasswordChange = {
  email: "",
};
export const BLANK_IRESET_PASSWORD: IResetPassword = {
  email: "",
  token: "",
  password: "",
};
export const BLANK_INEW_ACCOUNT_RESPONSE: INewAccountResponse = {
  firstName: "",
  lastname: "",
  phone: "",
  address: {},
  imageurl: "",
};
export const BLANK_IVERIFY_EMAIL: IVerifyEmail = {
  user: "",
  token: "",
};
export const BLANK_ICREATE_NEW_USER: IUserData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  type: "",
};
// #####################################################################

// #####################################################################
// IPersonal
export const BLANK_IPERSONAL: IPersonal = {
  oneLiner: "",
  about: "",
};
// #####################################################################

// #####################################################################
// IAddSkillsData
export const BLANK_IADD_SKILLS_DATA: IAddSkillsData = {
  title: "",
  skills: [],
};
export const BLANK_ICREATE_EXPERIENCE: ICreateExperience = {
  companyName: "",
  role: "",
  start: "",
  end: "",
  onGoing: false,
  description: "",
  fullPartTime: "",
};
export const BLANK_ICREATE_NEW_POST: ICreateNewPost = {
  regions: [],
  locations: [],
  start: "",
  end: "",
  payment: "",
  fullPartTimes: [],
  type: "",
  misc: "",
  id: -1,
};

// #####################################################################
