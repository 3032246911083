import axios from "axios";
// import { BLANK_SERVICE_RESPONSE_IDATA_LIST } from "../../constants/blankData/general/blankService";
import { BLANK_ISCHOOL_ADD_PHONE_AND_SCHOOL_RESPONSE } from "../../constants/blankData/school/blankSchool";
import { IPageData } from "../../models/componentProps/IPageDataInterface";
import IData from "../../models/general/IData";
import {
  ISchoolAddPhoneAndSchool,
  ISchoolAddPhoneAndSchoolResponse,
} from "../../models/school/ISchoolAddPhoneAndSchool";
import { ServiceResponse } from "../../models/serviceResponses/serviceResponse";
import { get, post } from "../ServicesBase";
const GET_SCHOOLS_ROUTE = "/School/search";
const GET_SCHOOLS = "/School/";

//
const ADD_SCHOOL_INFO_ROUTE = "School/";
const cancelToken = axios.CancelToken.source();

interface ISchoolUser extends IPageData {
  data: IData[];
}

// interface a {
//   pageNumber: number;
//   firstPage: string;
//   data: T
// }

interface IGetSchoolsOptions {
  pageNumber: number;
  pageSize: number;
  q: string;
}

export const getSchools = async () => {
  try {
    const response = await get<ServiceResponse<IData[]>>(`${GET_SCHOOLS}`);
    return response.data;
  } catch (e) {
    const error = e as Error;
    console.warn(error.message);
  }
};

export const getAllSchools = async (options: IGetSchoolsOptions) => {
  try {
    const response = await get<ServiceResponse<ISchoolUser>>(
      `${GET_SCHOOLS_ROUTE}?q=${options.q}&PageSize=${options.pageSize}&PageNumber=${options.pageNumber}`
    );
    return response.data;
  } catch (e) {
    const error = e as Error;
    console.warn(error.message);
    // 	if (error.name === "AbortError")
    // 		console.log("ABORTED:  GET_ALL_SCHOOLS Request.");
    // 	else console.warn(error.message);
    // 	return console.log("error");
  }
};

export const postSchoolInfo = async (
  data: ISchoolAddPhoneAndSchool,
  signal?: AbortSignal
): Promise<ServiceResponse<ISchoolAddPhoneAndSchoolResponse>> => {
  try {
    const response = await post<
      ServiceResponse<ISchoolAddPhoneAndSchoolResponse>
    >(ADD_SCHOOL_INFO_ROUTE, data, {
      signal: signal,
    });
    return response.data;
  } catch (e) {
    const error = e as Error;
    if (error.name === "AbortError")
      console.log("ABORTED:  POST_SCHOOL_USER_ADD_SCHOOL_AND_PHONE Request.");
    else console.warn(error.message);
    return {
      success: false,
      data: BLANK_ISCHOOL_ADD_PHONE_AND_SCHOOL_RESPONSE,
      error: error.message,
    };
  }
};

//get all students by a school user:
export const getSchoolUserStudents = async (route: string) => {
  const res = await get<ServiceResponse<any>>(route);

  return res;
};
