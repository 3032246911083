import { post, get } from "./ServicesBase";
import { ServiceResponse } from "../models/serviceResponses/serviceResponse";
import { IUserDetails, IUserDetails2 } from "../models/users/IUserDetails";
import {
  ICompany,
  ICompanyResponse,
} from "../models/serviceResponses/ICompanyResponse";
import { INewAccountResponse } from "../models/INewAccountResponse";
import { IStudentInformation } from "../models/IStudentInfomrationInterface";
import { IVerifyEmail } from "../models/IVerifyEmail";
import {
  IChangePassword,
  IRequestPasswordChange,
  IResetPassword,
} from "../models/ChangePassword";
import { IUserRegisterResponse } from "../models/serviceResponses/IRegisterResponse";
import axios, { AxiosRequestConfig } from "axios";

const ZIGG_URL = "https://embed-api-eu-west-1.ziggeo.com/v1/applications/";
// function to be deleted?
export async function postProfileInformation(information: any) {
  const route = "students/profile/update";
  const res = await post<ServiceResponse<INewAccountResponse>>(
    route,
    information
  );
  return res;
}

export async function updateStudentProfile(student: IStudentInformation) {
  const route = "students/profile/update";
  const res = await post<ServiceResponse<IStudentInformation>>(route, student);
  return res;
}

export async function getUserData() {
  let route = "Authentication/getuser";
  const res = await get<ServiceResponse<IUserDetails>>(route);
  return res;
}

export async function getStudentInformation(route: string) {
  const res = await get<ServiceResponse<IStudentInformation>>(route);
  return res;
}

export async function getCompanyProfileUser() {
  const route = `companyuser/Profile/companyuser`;
  const res = await get<ServiceResponse<IUserDetails2>>(route);
  return res;
}
export async function getCompanyProfile() {
  const route = `companyuser/Profile`;
  const res = await get<ServiceResponse<ICompanyResponse>>(route);
  return res;
}
export async function getCompanyUserData(route: string) {
  const res = await get<ServiceResponse<ICompanyResponse>>(route);
  return res;
}

// function to be deleted?
export async function postCompanyInformation(information: any) {
  const route = "company/profile/update";
  const res = await post<ServiceResponse<ICompanyResponse>>(route, information);
  return res;
}

interface IVerifyEmailResponse {
  user: IUserRegisterResponse;
  company: ICompany | null;
}

export const postEmailActivation = async (data: IVerifyEmail) => {
  const route = "authentication/validateemail";
  const res = await post<ServiceResponse<IVerifyEmailResponse>>(route, data);
  return res.data;
};

export const sendChangePassword = async (data: IChangePassword) => {
  const route = "password/changepassword";
  const res = await post<ServiceResponse<string>>(route, data);
  return res.data;
};

export const sendResetPasswordLink = async (data: IRequestPasswordChange) => {
  const route = "password/requestpasswordreset";
  const res = await post<ServiceResponse<string>>(route, data);
  return res.data;
};

export const resetPassword = async (data: IResetPassword) => {
  const route = "password/resetpassword";
  const res = await post<ServiceResponse<string>>(route, data);
  return res.data;
};
