import { useCallback, useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import useScreenSize from "../hooks/useScreenSize/useScreenSize";
import { ICompany } from "../models/serviceResponses/ICompanyResponse";
import { IUserRegisterResponse } from "../models/serviceResponses/IRegisterResponse";
import { getUserData, postEmailActivation } from "../services/account.service";
import {
  connectToExistingCompany,
  connectToNewCompany,
} from "../services/companies/company.connect.service";
import "./ActivateAccount.scss";

const BLANK_USER_DETAILS: IUserRegisterResponse = {
  id: "-1",
  email: "",
  firstname: "",
  lastname: "",
  type: "",
  imageUrl: "",
  phoneNumber: "",
};

export const ActivateAccount = () => {
  const { saveLoggedInUser } = useContext(UserContext);
  const [activated, setActivated] = useState(false);
  const [error, setError] = useState("");
  const [userDetails, setUserDetails] =
    useState<IUserRegisterResponse>(BLANK_USER_DETAILS);
  // const [matchingCompany, setMatchingCompany] = useState<ICompany | null>(null);
  const [redirectTimer, setRedirectTimer] = useState<number>(5);
  const { breakpoints } = useScreenSize();
  const search = useLocation().search;
  const navigate = useNavigate();

  // useEffect(() => {
  //   getUserData().then((res) => {
  //     if (res.data.data.isActivated) {
  //       navigate("/");
  //     }
  //   });
  // }, []);

  // check For Matching Company
  const connectCompany = useCallback(async () => {
    try {
      const res = await getUserData();
      if (res.data.success) {
        saveLoggedInUser(
          res.data.data.email,
          res.data.data.type,
          res.data.data.id
        );
        return navigate("/company/details");
      } else console.error(res.data.error);
    } catch (error) {
      console.error((error as Error).message);
    }
  }, [navigate, saveLoggedInUser]);

  // check For Matching Company
  //  const checkForMatchingCompany = useCallback(async () => {
  //   try {
  //     if (matchingCompany === null) {
  //       const res = await connectToNewCompany();
  //       if (res.success) {
  //         saveLoggedInUser(res.data.email, res.data.type, res.data.id);
  //         return navigate("/company/details");
  //       } else console.error(res.error);
  //     } else {
  //       const res = await connectToExistingCompany(matchingCompany.id);
  //       if (res.success) {
  //         saveLoggedInUser(res.data.email, res.data.type, res.data.id);
  //         return navigate("/company/profile");
  //       } else console.error(res.error);
  //     }
  //   } catch (error) {
  //     console.error((error as Error).message);
  //   }
  // }, [matchingCompany, navigate, saveLoggedInUser]);

  useEffect(() => {
    if (activated || error !== "") {
      return;
    }
    const user = new URLSearchParams(search).get("user");
    const token = new URLSearchParams(search).get("token");

    let isMounted = true;
    // Call api to activate
    if (user && token) {
      postEmailActivation({ user, token }).then((response) => {
        if (isMounted) {
          if (response.success) {
            if (userDetails.id === "-1") {
              setActivated(true);
              setUserDetails(response.data.user);
              //setMatchingCompany(response.data.company);
            }
          } else {
            setError(response.error);
          }
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [activated, error, search, userDetails.id]);

  useEffect(() => {
    if (redirectTimer <= 0) return;

    const intervalId = setInterval(() => {
      if (activated) {
        if (userDetails.id === "-1") return;
        setRedirectTimer((prev) => prev - 1);

        if (userDetails.type === "Student") {
          if (redirectTimer <= 1) {
            saveLoggedInUser(
              userDetails.email,
              userDetails.type,
              userDetails.id
            );
            navigate("/intern/addprofileimage");
          }
        }

        if (userDetails.type === "Company") {
          if (redirectTimer <= 1) {
            return connectCompany();
          }
        }

        if (userDetails.type === "School") {
          if (redirectTimer <= 1) {
            saveLoggedInUser(
              userDetails.email,
              userDetails.type,
              userDetails.id
            );
            navigate("/schooluser/addinfo");
          }
        }
      } else return;
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    userDetails.id,
    userDetails.type,
    userDetails.email,
    navigate,
    activated,
    redirectTimer,
    saveLoggedInUser,
    //checkForMatchingCompany,
  ]);

  return (
    <div className="activate-container">
      {!breakpoints.IS_MOBILE ? (
        <div className="blue-div">
          {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
            !activated ? (
              <h1>Oops</h1>
            ) : (
              <h1>Aktiverat!</h1>
            )
          ) : (
            <>{null}</>
          )}
        </div>
      ) : (
        <>{null}</>
      )}
      <div className="content-container">
        {breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL ? (
          !activated ? (
            <>
              <h1>Oops</h1>
            </>
          ) : (
            <h1>Activated</h1>
          )
        ) : (
          <>{null}</>
        )}
        {!activated ? (
          <>
            <h2>Någonting blev fel: {error}</h2>
          </>
        ) : (
          <>
            <h2>Ditt konto är nu aktiverat!</h2>
            <p className="redirect-text">
              Du blir nu automatiskt tillbaka skickad till att fortsätta fylla i
              dina uppgifter om: <span>{redirectTimer}</span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
