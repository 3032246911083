import "./studentSuccess.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowRightLine } from "react-icons/ri";
import useScreenSize from "../../../hooks/useScreenSize/useScreenSize";

export const StudentSuccess = () => {
  const navigate = useNavigate();
  const { breakpoints } = useScreenSize();
  const query = new URLSearchParams(window.location.search);

  let html = <></>;
  if (query.get("success")) {
    html = (
      <>
        {!breakpoints.IS_MOBILE ? (
          <div className="blue-div">
            {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
              <h1>Välkommen till Hopen!</h1>
            ) : (
              <>{null}</>
            )}
          </div>
        ) : (
          <>{null}</>
        )}
        <div className="content-container">
          {breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL ? (
            <h1 className="mobilesize-heading">Välkommen till Hopen!</h1>
          ) : (
            <>{null}</>
          )}
          <h2>Ditt kort är nu registrerat</h2>
          <p>
            Nu har du tillgång till de följande tjänster från Hopen:
            <ul>
              <li>
                Mail när ett företag lägger upp en annons som matchar dina
                skills
              </li>
            </ul>
          </p>
          <button
            onClick={() => navigate("/student/profile")}
            className="secondary-btn"
          >
            Gå till profil <RiArrowRightLine className="icon" />
          </button>
        </div>
      </>
    );
  }

  if (query.get("canceled")) {
    html = (
      <>
        {!breakpoints.IS_MOBILE ? (
          <div className="blue-div">
            {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
              <h1></h1>
            ) : (
              <>{null}</>
            )}
          </div>
        ) : (
          <>{null}</>
        )}
        (
        <div className="content-container">
          <h2>Din registrering av prenumeration på Hopen blev avbruten</h2>
          <p>
            Om du vill börja prenumerera på vår superba tjänst senare så klickar
            du bara på Premium på din profilsida.
          </p>
          <p>
            Under tiden är ett hett tips att fortsätta lägga till information i
            din profil och tidsperioder för när du har praktik.
          </p>
          <button
            className="primary-btn"
            onClick={() => navigate("/student/profile")}
          >
            Gå till profil <RiArrowRightLine className="icon" />
          </button>
        </div>
        )
      </>
    );
  }

  return <div className="success-container">{html}</div>;
};
