import { useCallback, useEffect, useState } from "react";
import SchoolUserProfileForm from "../../../components/forms/school/schoolUserProfileForm/SchoolUserProfileForm";
import SchoolUserProgramsForm from "../../../components/forms/school/schoolUserProgramsForm/SchoolUserProgramsForm";
import { BLANK_ISCHOOL_USER } from "../../../constants/blankData/school/blankSchoolUser";
import { isCancel } from "../../../helpers/AbortHelpers";
import { ISchoolUser } from "../../../models_NEW_STRUCTURE/general/ISchoolUser";
import { getSchoolProfile } from "../../../services_NEW_STRUCTURE/Profile/SchoolProfile.service";
import "./schoolUserProfile.scss";
import { RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

let fetchDataController: AbortController;

// COMPONENT
export function SchoolUserProfile() {
  // STATE FOR FORMDATA
  const [schoolUserProfileInfo, setSchoolUserProfileInfo] =
    useState<ISchoolUser>(BLANK_ISCHOOL_USER);

  const navigate = useNavigate();

  // STATE FOR ERROR HANDLING
  const [error, setError] = useState<string>("");

  // CALLBACK FOR FETCHING USER-DATA
  const fetchSchoolUserData = useCallback(async (signal: AbortSignal) => {
    try {
      const res = await getSchoolProfile(signal);

      if (res.success) {
        setSchoolUserProfileInfo(res.data);
      } else {
        setError(res.error);
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
  }, []);

  // USEEFFECT FOR RENDERING USER-DATA
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (fetchDataController) fetchDataController.abort();
      fetchDataController = new AbortController();
      fetchSchoolUserData(fetchDataController.signal);
    }
    return () => {
      if (fetchDataController) {
        fetchDataController.abort();
      }
      isMounted = false;
    };
  }, []);

  return (
    <main id="school-user-profile">
      <section className="section-left">
        <SchoolUserProgramsForm />
      </section>
      <section className="section-right">
        <SchoolUserProfileForm
          schoolUserInfo={schoolUserProfileInfo}
          fetchSchoolUserData={() => {
            if (fetchDataController) {
              fetchDataController.abort();
            }
            fetchDataController = new AbortController();
            fetchSchoolUserData(fetchDataController.signal);
          }}
        />
        <button
          className="view-students-button"
          onClick={() => navigate(`/schooluser/studentOverview`)}
        >
          Se dina studenter <RiArrowRightLine />
        </button>
      </section>
    </main>
  );
}
export default SchoolUserProfile;
