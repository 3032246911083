import { FormEvent, useCallback, useEffect, useState } from "react";
import {
  RiAddCircleLine,
  RiAddLine,
  RiCloseLine,
  RiPencilLine,
  RiSubtractLine,
  RiToolsFill,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { isCancel } from "../../../../helpers/AbortHelpers";
import { hasOnlyNumbers } from "../../../../helpers/StringValidations";
import useObjectState from "../../../../hooks/useObjectState/useObjectState";
import ISchoolEducationProgram from "../../../../models_NEW_STRUCTURE/ServiceResponses/Education/ISchoolEducationProgram";
import ISchoolEducationProgramNew from "../../../../models_NEW_STRUCTURE/ServiceResponses/Education/ISchoolEducationProgramNew";
import {
  getSchoolEducation,
  postSchoolEducation,
} from "../../../../services_NEW_STRUCTURE/SchoolEducation.service";
import EducationalProgramCard from "../../../school/cards/EducationalProgramCard/EducationalProgramCard";
import "./schoolUserProgramsForm.scss";
import IData from "../../../../models_NEW_STRUCTURE/general/IData";
import { BLANK_IDATA } from "../../../../constants/blankData/general/blankGeneral";

interface IInputValidity {
  name: boolean;
  code?: boolean;
  year: boolean;
  url?: boolean;
}

interface IInputStates {
  education: IData;
  code?: string;
  year: string;
  url?: string;
}

const BLANK_INPUT_VALIDITY: IInputValidity = {
  name: true,
  code: true,
  year: true,
  url: true,
};

const BLANK_INPUT_STATES: IInputStates = {
  education: BLANK_IDATA,
  code: "",
  year: "",
  url: "",
};

// ABORTCONTROLLERS
let fetchEducationalProgramsController: AbortController;
let addNewEducationalProgramController: AbortController;

// COMPONENT
const SchoolUserProgramsForm = () => {
  // NAVIGATION
  const navigate = useNavigate();

  // STATE FOR ERROR HANDLING
  const [error, setError] = useState<string>("");

  // STATE FOR FORM INPUTS DISABLED vs USABLE
  const [displayAddEducationsForm, setDisplayAddEducationsForm] =
    useState<boolean>(false);

  const [collapseForm, setCollapseForm] = useState<boolean>(true);

  // STATE FOR FORM INPUTS DISABLED vs USABLE
  const [inputsDisabled, setInputsDisabled] = useState<boolean>(true);

  // STATE FOR EDITING
  const [editProgram, setEditProgram] = useState<boolean>(false);

  // STATE FOR INPUT FIELDS
  const [inputFields, setInputFields] = useObjectState<IInputStates>({
    education: BLANK_IDATA,
    code: "",
    year: "",
    url: "",
  });

  // STATE FOR LIST OF EDUCATIONPROGRAMS
  const [schoolUserEducations, setSchoolUserEducations] = useState<
    ISchoolEducationProgram[]
  >([]);

  // VALIDATION STATES
  const [validStates, setValidStates] =
    useObjectState<IInputValidity>(BLANK_INPUT_VALIDITY);

  const [liveValidate, setLiveValidate] = useState(false);

  const [validationMsg, setValidationMsg] = useObjectState<IInputStates>({
    education: {
      name: "Du måste ange en giltig programtitel, endast text.",
      id: -1,
    },
    code: "Du måste ange en programkod.",
    year: "Du måste ange startår för utbildningen.",
    url: "Du måste ange en giltig URL för din utbildning.",
  });

  // CHECK TO SEE IF INPUT IS VALID
  const isFormValid = (): boolean => {
    let isValid = true;
    const validity: IInputValidity = { ...BLANK_INPUT_VALIDITY };
    const errorMsgs: IInputStates = { ...validationMsg };

    // PROGRAM NAME CHECK
    if (!inputFields.education.name) {
      isValid = false;
      validity.name = false;
    }

    // PROGRAM CODE CHECK
    // if (isEmpty(inputFields.code)) {
    //   isValid = false;
    //   validity.code = false;
    // }

    // PROGRAM YEAR CHECK
    if (!hasOnlyNumbers(inputFields.year) || inputFields.year.length !== 4) {
      isValid = false;
      validity.year = false;
    }

    // URL CHECK
    // ADDS PROTOCOL TO URL IF IT DOESN'T HAVE IT
    // if (!hasProtocol(inputFields.url)) {
    //   isValid = false;
    //   validity.url = false;
    // }

    // UPDATE VALIDITY STATES
    setValidStates({
      ...validity,
    });

    // IF isValid = false, WE HAVE INVALID INPUTS
    if (!isValid && !inputsDisabled) {
      setLiveValidate(true);
      setValidationMsg({
        ...errorMsgs,
      });

      return false;
    } else return true;
  };

  // USEEFFECT FOR LIVE VALIDATION
  useEffect(() => {
    if (liveValidate) isFormValid();
  }, [inputFields]);

  // CALLBACK FOR FETCHING EDU PROGRAMS-DATA
  const fetchEduPrograms = useCallback(async (signal: AbortSignal) => {
    try {
      const res = await getSchoolEducation(signal);
      if (res.success) {
        setSchoolUserEducations(res.data);
      } else {
        setError(res.error);
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
  }, []);

  // USEFFECT FOR RENDERING EDU PROGRAMS-DATA
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (fetchEducationalProgramsController)
        fetchEducationalProgramsController.abort();
      fetchEducationalProgramsController = new AbortController();
      fetchEduPrograms(fetchEducationalProgramsController.signal);
    }
    return () => {
      if (fetchEducationalProgramsController) {
        fetchEducationalProgramsController.abort();
      }
      isMounted = false;
    };
  }, []);

  // POST NEW PROGRAMS
  const addNewEducationalProgram = async (e: FormEvent) => {
    e.preventDefault();

    if (addNewEducationalProgramController)
      addNewEducationalProgramController.abort();
    addNewEducationalProgramController = new AbortController();

    try {
      if (isFormValid()) {
        const data: ISchoolEducationProgramNew = {
          ...inputFields,
          url: inputFields.url,
        };

        const res = await postSchoolEducation(
          data,
          addNewEducationalProgramController.signal
        );

        if (res.success) {
          fetchEduPrograms(addNewEducationalProgramController.signal);
          setInputFields(BLANK_INPUT_STATES);
          setInputsDisabled(true);
          handleFormCollapse();
        } else {
          console.error(res.error);
        }
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      console.error((error as Error).message);
    }
  };

  const handleFormCollapse = () => {
    setCollapseForm(!collapseForm);
  };

  console.log(inputFields);

  return (
    <div id="school-user-education-container">
      <div className="heading-edit-button-container">
        {schoolUserEducations.length <= 0 ? null : (
          <>
            <h1 className="header">
              <RiToolsFill className="icon" /> Utbildningar
            </h1>
            <button
              type="button"
              className="edit-educations-button"
              onClick={() => {
                setInputsDisabled(!inputsDisabled);
                if (inputsDisabled) {
                  setEditProgram(false);
                }
              }}
            >
              {inputsDisabled ? (
                <RiPencilLine className="icon" />
              ) : (
                <RiCloseLine className="icon" />
              )}
            </button>
          </>
        )}
      </div>
      <div className="program-cards-container">
        {schoolUserEducations
          ? schoolUserEducations.map((programCard, index) => {
              return (
                <div
                  className="educations-container"
                  key={programCard.education.id}
                >
                  <EducationalProgramCard
                    programCard={programCard}
                    fetchEduPrograms={fetchEduPrograms}
                    inputsDisabled={inputsDisabled}
                  />
                </div>
              );
            })
          : null}
      </div>
      <h2>
        <button
          className="add-new-education-button"
          onClick={() => handleFormCollapse()}
        >
          {collapseForm ? (
            <>
              Lägg till utbildning <RiAddLine className="icon" />
            </>
          ) : (
            <>
              Göm formulär <RiSubtractLine className="icon" />
            </>
          )}
        </button>
      </h2>
      {!collapseForm ? (
        <>
          <div className="education-name-input-container">
            <label htmlFor="education-name-input">Namn på utbildning*</label>
            <input
              id="education-name-input"
              type="text"
              value={inputFields.education.name}
              onChange={(e) => {
                setInputFields({ education: { name: e.target.value, id: -1 } });
              }}
              required={true}
            />
            {!validStates.name ? (
              <span>{validationMsg.education.name}</span>
            ) : (
              ""
            )}
          </div>
          {/* <div className="education-code-input-container">
              <label htmlFor="education-code-input">Programkod*</label>
              <input
                id="education-code-input"
                type="text"
                value={inputFields.code}
                onChange={(e) => {
                  setInputFields({ code: e.target.value });
                }}
                required={true}
              />
              {!validStates.code ? <span>{validationMsg.code}</span> : ""}
            </div> */}
          <div className="education-year-input-container">
            <label htmlFor="education-year-input">Utbildningens startår*</label>
            <input
              id="education-year-input"
              type="text"
              value={inputFields.year}
              onChange={(e) => {
                setInputFields({ year: e.target.value });
              }}
              required={true}
            />
            {!validStates.year ? <span>{validationMsg.year}</span> : ""}
          </div>
          {/* <div className="education-url-input-container">
              <label htmlFor="education-url-input">URL till utbildning*</label>
              <input
                id="education-url-input"
                type="url"
                value={inputFields.url}
                onChange={(e) => {
                  // ADDS PROTOCOL TO URL IF IT DOESN'T HAVE IT
                  let url = hasProtocol(e.target.value)
                    ? e.target.value
                    : "https://" + e.target.value;
                  setInputFields({ url });
                }}
                required={true}
              />
              {!validStates.url ? <span>{validationMsg.url}</span> : ""}
            </div> */}
          {displayAddEducationsForm ? (
            <></>
          ) : (
            <button
              type="submit"
              className="add-education-button"
              onClick={addNewEducationalProgram}
            >
              <RiAddCircleLine className="icon" />
            </button>
          )}
        </>
      ) : null}
      {/* <button
        className="view-students-button"
        onClick={() => navigate(`/schooluser/studentOverview`)}
      >
        Se dina studenter <RiArrowRightLine />
      </button> */}
    </div>
  );
};

export default SchoolUserProgramsForm;
