import {
  ICompanyDetails,
  ICompanyPaymentInfo,
} from "../models/ICompanyDetails";
import {
  ICompanyInternship,
  RadioBtnObjects,
} from "../models/IInternshipInterface";
import { ICompanyProfileResponse } from "../models/serviceResponses/ICompanyProfileResponse";
import { ICompanyInternshipId } from "../models/serviceResponses/ICompanyResponse";
import {
  companyDetails,
  ICompanyIntenshipsResponse,
  ICompanyResponse,
} from "../models/serviceResponses/ICompanyResponse";

import { INewCompanyResponse } from "../models/serviceResponses/INewCompanyResponse";
import { ISearchResponse } from "../models/serviceResponses/ISearchResponse";
import { ServiceResponse } from "../models/serviceResponses/serviceResponse";
import { get, post, Delete, put } from "./ServicesBase";

// function to be deleted?
export const postNewCompany = async (data: any) => {
  const route = "company/profile/create";
  const res = await post<ServiceResponse<INewCompanyResponse>>(route, data);
  return res;
};

export const getCompanyData = async () => {
  const route = "companyuser/description";
  const res = await get<ServiceResponse<ICompanyResponse>>(route);
  return res;
};

export const getCompanyDataByUserId = async (
  userId: string,
  signal?: AbortSignal
) => {
  const route = `company/profile/user/${userId}`;
  const res = await get<ServiceResponse<ICompanyResponse>>(route, { signal });
  return res.data;
};

export const getCompanyProfile = async (signal?: AbortSignal) => {
  const route = "company/profile";
  const res = await get<ServiceResponse<ICompanyProfileResponse>>(route, {
    signal,
  });
  return res;
};

export const addNewCompanyUser = async (data: any) => {
  const route = "company/profile/adduser";
  const res = await post<ServiceResponse<any>>(route, data);
  return res;
};

export const addNewInternship = async (data: ICompanyInternship) => {
  const route = "company/internship/create";
  const res = await post<ServiceResponse<ICompanyInternship[]>>(route, data);
  return res;
};

export const addContactDetails = async (data: companyDetails) => {
  const route = "company/Profile/contactinfo";
  const res = await post<ServiceResponse<companyDetails>>(route, data);
  return res;
};

export const addCompanyDescription = async (data: ICompanyDetails) => {
  const route = "company/Profile/update";
  const res = await post<ServiceResponse<ICompanyDetails>>(route, data);
  return res;
};

export const addCompanySubscription = async (id: string) => {
  const route = `company/profile/subscribe/${id}`;
  const res = await put<ServiceResponse<ICompanyDetails>>(route, id);
  return res.data;
};

/* export const companyUnSubscription = async (id: string) => {
  const route =`company/profile/unsubscribe/${id}`;
  const res = await put<ServiceResponse<ICompanyDetails>>(route, id);
  return res.data;
};
 */
export const addCompanyInvoice = async (data: ICompanyPaymentInfo) => {
  const route = "company/Profile/paymentinfo";
  const res = await post<ServiceResponse<ICompanyPaymentInfo>>(route, data);
  return res;
};

export const editCompanyInternship = async (
  id: number,
  internship: ICompanyInternship
) => {
  const route = `company/Internship/${id}`;
  const res = await put<ServiceResponse<ICompanyInternship>>(route, internship);
  return res;
};

export const deleteCompanyInternship = async (id: number) => {
  const route = `company/Internship/${id}`;
  const res = await Delete<ServiceResponse<any>>(route);
  return res;
};

export const findCompanyInternships = async (route: string) => {
  const res = await get<
    ServiceResponse<ISearchResponse<ICompanyIntenshipsResponse[]>>
  >(route);
  return res;
};

export const findchosenCompanyInternship = async (id: number) => {
  const route = `student/Internship/${id}`;
  const res = await get<ServiceResponse<ICompanyInternshipId>>(route);
  return res;
};
