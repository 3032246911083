import { ServiceResponse } from "../models/serviceResponses/serviceResponse";
import {
  SubscriptionResponse,
  SubscriptionStatus,
} from "../models/SubscriptionStatus";
import { get, post } from "./ServicesBase";

export const createCheckoutSession = async (lookupKey: string) => {
  const route = "company/payment/create-checkout-session";
  const res = await post<ServiceResponse<string>>(route, lookupKey);
  return res.data;
};

export const createRegisterCheckoutSession = async (lookupKey: string) => {
  const route = "company/payment/create-register-checkout-session";
  const res = await post<ServiceResponse<string>>(route, lookupKey);
  return res.data;
};

export const createPortalSession = async () => {
  const route = "company/payment/create-portal-session";
  const res = await post<ServiceResponse<string>>(route, null);
  return res.data;
};

export const getSubscriptionFromUser = async () => {
  const route = "company/payment/getSubscription";
  const res = await get<ServiceResponse<SubscriptionStatus>>(route);
  return res.data;
};

export const checkPaymentStatus = async () => {
  const route = "company/payment/checkSubscription";
  const res = await get<ServiceResponse<SubscriptionResponse>>(route);
  return res.data;
};

export const createRegisterCheckoutSessionForStudent = async () => {
  const route = "student/payment/create-register-checkout-session";
  const res = await post<ServiceResponse<string>>(route, null);
  return res.data;
};

export const createPortalSessionForStudent = async () => {
  const route = "student/payment/create-portal-session";
  const res = await post<ServiceResponse<string>>(route, null);
  return res.data;
};
