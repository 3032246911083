import IUser from "../../../models_NEW_STRUCTURE/general/IUser";

export const BLANK_IUSER: IUser = {
  id: "",
  firstname: "",
  lastname: "",
  email: "",
  phoneNumber: "",
  imageUrl: "",
  type: "",
  lastLoggedIn: "",
  hasUnreadMessages: false,
};
