import { ServiceResponse } from "../../models/serviceResponses/serviceResponse";
import IData from "../../models_NEW_STRUCTURE/general/IData";
import { ISchoolUser } from "../../models_NEW_STRUCTURE/general/ISchoolUser";
import { get, post, put } from "../../services/ServicesBase";

const SCHOOL_PROFILE_ROUTE = "school/profile";

// ========================================  GET METHODS  ========================================
// GET      /school/Profile
export const getSchoolProfile = async (signal?: AbortSignal) => {
  const res = await get<ServiceResponse<ISchoolUser>>(SCHOOL_PROFILE_ROUTE, {
    signal: signal,
  });
  return res.data;
};

// GET      /school/Profile/profile/:id
export const getSchoolProfileById = async (
  id: string,
  signal?: AbortSignal
) => {
  const res = await get<ServiceResponse<ISchoolUser>>(
    `${SCHOOL_PROFILE_ROUTE}/${id}`,
    {
      signal: signal,
    }
  );
  return res.data;
};

// ========================================  POST METHODS  ========================================
// POST      /school/Profile/addPhoneAndSchool
// interface IPostSchoolProfileAddPhoneAndSchool {
//   school: IData;
//   phonenumber: string;
// }
// export const postSchoolProfileAddPhoneAndSchool = async (
//   data: IPostSchoolProfileAddPhoneAndSchool,
//   signal?: AbortSignal
// ) => {
//   const res = await post<ServiceResponse<ISchoolUser>>(
//     `${SCHOOL_PROFILE_ROUTE}/addphoneandschool`,
//     data,
//     {
//       signal: signal,
//     }
//   );
//   return res.data;
// };

// ========================================  POST METHODS  ========================================
// POST      /school/Profile/addSchool
interface IPostSchoolProfileAddSchool {
  school: IData;
}
export const postSchoolProfileAddSchool = async (
  data: IPostSchoolProfileAddSchool,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<ISchoolUser>>(
    `${SCHOOL_PROFILE_ROUTE}/addschool`,
    data,
    {
      signal: signal,
    }
  );
  return res.data;
};

// ========================================  PUT METHODS  ========================================
// PUT      /school/Profile/update/:id
export const putSchoolProfileUpdateById = async (
  id: string,
  data: ISchoolUser,
  signal?: AbortSignal
) => {
  const res = await put<ServiceResponse<ISchoolUser>>(
    `${SCHOOL_PROFILE_ROUTE}/update/${id}`,
    data,
    {
      signal: signal,
    }
  );
  return res.data;
};
