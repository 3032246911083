import IData from "../../../models/general/IData";
import IImage from "../../../models/general/IFile";
import ITags from "../../../models/general/ITags";
import IAddress from "../../../models/IAddress";
import { IContact } from "../../../models/serviceResponses/ICompanyResponse";
import { ICurrentEducation } from "../../../models/serviceResponses/IEducationResponse";

// #####################################################################
// IData, ITags
export const BLANK_IDATA: IData = {
  name: "",
  id: -1,
};
export const BLANK_ITAG: ITags = {
  name: "",
};
export const BLANK_IIMAGE: IImage = {
  file: "",
  name: "",
  type: "",
};
export const BLANK_ICURRENT_EDUCATION: ICurrentEducation = {
  education: {
    name: "",
    id: -1,
  },
  year: "",
  code: "",
  url: "",
  school: {
    name: "",
    id: -1,
  },
};
// #####################################################################

// #####################################################################
// IAddress, IContact
export const BLANK_IADDRESS: IAddress = {
  street: "",
  zip: "",
  city: "",
};
export const BLANK_ICONTACT: IContact = {
  address: BLANK_IADDRESS,
  email: "",
  firstname: "",
  lastname: "",
  phoneNumber: "",
  imageUrl: "",
  type: "",
};
// #####################################################################
