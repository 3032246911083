import { useLocation } from "react-router";
import AdComponent from "../adComponent/adComponent";
import { GOOGLE_SLOT_RESPONSIVE } from "../../pages/general/ads/googleClient";


const BannerAd = () =>  {
    const location = useLocation()
    return (
        <div key={location.pathname}>
            {/* <AdComponent width="100%" height="100%" adslot={GOOGLE_SLOT_RESPONSIVE} format="auto"></AdComponent> */}
        </div>
    )
}
export default BannerAd;