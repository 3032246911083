import "./dropdownSearchSchools.scss";
import { ReactElement, useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick/useOutsideClick";
import IData from "../../../models/general/IData";
import Spinner from "../spinner/Spinner";
import { RiCheckLine, RiCloseLine, RiSearchLine } from "react-icons/ri";
import { areObjectsEqual } from "../../../helpers/ObjectValidations";
import { areListsEqual } from "../../../helpers/ListValidations";
import { isCancel } from "../../../helpers/AbortHelpers";
import { FiTrash2 } from "react-icons/fi";
import useObjectState from "../../../hooks/useObjectState/useObjectState";
import IPaginatedData from "../../../models/general/IPaginatedData";
import { BLANK_IPAGINATED_DATA_IDATA_LIST } from "../../../constants/blankData/general/blankPaginatedData";
import { getSchoolsSearch } from "../../../services_NEW_STRUCTURE/School.service";
import { BeatLoader } from "react-spinners";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver/useIntersectionObserver";

interface IProps {
  activeSchools: IData[];
  onSchoolsChange: (schools: IData[]) => void;

  // OPTIONAL
  onSearch?: (value: string) => void;

  // IF TRUE, ONLY ALLOWS SINGLE SCHOOL TO BE CHOSEN.
  // onSchoolsChange WILL SEND BACK A LIST WITH ONLY
  // ONE IDATA ELEMENT IN IT.
  singleChoice?: boolean;

  // MORE OPTIONAL CONFIGS
  id?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  menuPosition?: "absolute" | "relative";
  closeOnSelect?: boolean;
  openOnFocus?: boolean;
  minimumWaitTime?: number;
  onError?: (error: string) => void;
  testId?: string;
}

interface ISearchParams {
  searchTerm: string;
  pageNumber: number;
  reset: boolean;
  doFetch: boolean;
}

const PAGE_SIZE = 10; // NUMBER OF RESULTS IN EACH SCHOOLS FETCH
const MINIMUM_INPUT_WAIT_TIME = 500; // In milliseconds
let timer: NodeJS.Timeout | null = null;
let abortController: AbortController;

const DropdownSearchSchools = (props: IProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState("");

  const [activeSchools, setActiveSchools] = useState<IData[]>(
    props.activeSchools
  );
  const [searchResults, setSearchResults] = useState<IData[]>([]);
  const [pagData, setPagData] = useObjectState<IPaginatedData<IData[]>>(
    BLANK_IPAGINATED_DATA_IDATA_LIST
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => {
    if (props.singleChoice) {
      if (activeSchools[0]) setInputText(activeSchools[0].name);
      setSearchParams({
        doFetch: false,
      });
    }
    setIsOpen(false);
  });

  const handleSelect = (entry: IData) => {
    if (props.closeOnSelect) setIsOpen(false);
    if (activeSchools.find((tag) => tag.id === entry.id)) {
      if (!props.singleChoice) removeActiveSchool(entry);
    } else {
      if (props.singleChoice) {
        setInputText(entry.name);
        setSearchParams({
          doFetch: false,
        });
        setActiveSchools([entry]);
        props.onSchoolsChange([entry]);
      } else {
        setActiveSchools([...activeSchools, entry]);
        props.onSchoolsChange([...activeSchools, entry]);
      }
    }
  };

  const removeActiveSchool = (givenSchool: IData) => {
    const filtered = activeSchools.filter((school) => {
      return !areObjectsEqual(givenSchool, school);
    });
    setActiveSchools(filtered);
    props.onSchoolsChange(filtered);
  };

  // FETCH SCHOOL AND UPDATE FETCH PARAMS
  const [searchParams, setSearchParams] = useObjectState<ISearchParams>({
    searchTerm: "",
    pageNumber: 1,
    reset: true,
    doFetch: true,
  });

  useEffect(() => {
    if (!searchParams.doFetch) return;
    setIsLoading(true);
    if (timer) clearTimeout(timer);
    timer = setTimeout(
      () => {
        if (props.onSearch) props.onSearch(searchParams.searchTerm);
        if (searchParams.searchTerm.length <= 0) {
          setSearchResults([]);
          setIsLoading(false);
          return;
        } else {
          fetchSchools();
        }
      },
      props.minimumWaitTime ? props.minimumWaitTime : MINIMUM_INPUT_WAIT_TIME
    );
    return () => {
      if (abortController) abortController.abort();
    };
  }, [searchParams.searchTerm, searchParams.pageNumber]);

  const fetchSchools = async () => {
    try {
      if (abortController) abortController.abort();
      abortController = new AbortController();
      const res = await getSchoolsSearch(
        searchParams.pageNumber,
        PAGE_SIZE,
        searchParams.searchTerm,
        abortController.signal
      );
      if (res.success) {
        setPagData(res.data);
        if (searchParams.reset) setSearchResults(res.data.data);
        else setSearchResults([...searchResults, ...res.data.data]);
        setIsOpen(true);
      } else {
        props.onError ? props.onError(res.error) : console.warn(res.error);
        setSearchResults([]);
      }
    } catch (e) {
      if (isCancel(e as Error)) return;
      props.onError
        ? props.onError((e as Error).message)
        : console.warn((e as Error).message);
      setPagData(BLANK_IPAGINATED_DATA_IDATA_LIST);
    }
    setIsLoading(false);
  };

  // INTERSECTIION OBSERVER
  const menuRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useIntersectionObserver<HTMLDivElement>({
    root: menuRef.current,
  });
  useEffect(() => {
    if (!inView) return;
    const hasMore = pagData.totalPages > pagData.pageNumber;
    if (!hasMore || isLoading) return;
    setSearchParams({
      pageNumber: searchParams.pageNumber + 1,
      reset: false,
      doFetch: true,
    });
  }, [inView]);

  // MAKE SURE TO UPDATE ACTIVE SCHOOLS IF INTERN DATA HAS CHANGED. ALSO MAKE
  // SURE TO RERENDER THIS COMPONENT IF IT RENDERES BEFORE THE FETCH FOR
  // INTERN DATA FINISHES.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // console.log("ACTIVE SCHOOLS CHANGED: ", props.activeSchools);
      if (props.singleChoice)
        setInputText(
          props.activeSchools[0]?.name ? props.activeSchools[0].name : ""
        );
      setActiveSchools(props.activeSchools);
    }
    return () => {
      isMounted = false;
    };
  }, [props.activeSchools]);

  // CALCULATE PLACEHOLDER
  const calcPlaceholder = () => {
    if (activeSchools.length <= 0) return "Sök efter skolor här";
    if (activeSchools.length === 1) return "1 vald";
    return `${activeSchools.length} valda`;
  };

  const hasMore = pagData.totalPages > pagData.pageNumber;
  return (
    <div
      data-testid={props.testId ? props.testId : "dropdown"}
      ref={dropdownRef}
      className={`dropdown-search-schools${
        props.disabled === true ? " Disabled" : ""
      }`}
    >
      {props.label && (
        <label className="input-label" htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <div className="top">
        <div className="text-field">
          <input
            disabled={props.disabled === true ? props.disabled : false}
            id={props.id ? props.id : ""}
            type="text"
            name={props.name}
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
              setSearchParams({
                searchTerm: e.target.value,
                pageNumber: 1,
                reset: true,
                doFetch: true,
              });
            }}
            placeholder={props.singleChoice ? "Sök skola" : calcPlaceholder()}
            onFocus={() => {
              if (props.openOnFocus) setIsOpen(true);
            }}
          />

          {isLoading ? (
            <Spinner size="small" />
          ) : (
            <RiSearchLine className="magnify" />
          )}
        </div>
        {activeSchools.length > 0 && !props.singleChoice ? (
          <div
            className={`active-tags ${
              props.singleChoice ? "single-choice" : ""
            }`}
          >
            <div className="tags-wrapper">
              {activeSchools.map((tag, index) => {
                return (
                  <div key={index} className="tag">
                    <span>{tag.name}</span>
                    <RiCloseLine
                      className="remove-tag-button"
                      onClick={() => {
                        removeActiveSchool(tag);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            {!props.singleChoice && (
              <span
                className="clear-tags"
                onClick={() => {
                  setActiveSchools([]);
                  props.onSchoolsChange([]);
                }}
              >
                <FiTrash2 />
              </span>
            )}
          </div>
        ) : null}
      </div>
      <div
        ref={menuRef}
        className={`menu ${isOpen ? "opened" : "closed"}${
          props.menuPosition ? " " + props.menuPosition : ""
        }`}
      >
        {searchResults.length <= 0 ? (
          <div className="entry disabled hint">
            <span>Inga träffar...</span>
          </div>
        ) : (
          <>
            {searchResults.map((tag: IData, index) => {
              if (!tag.id || !tag.name || tag.name.length <= 0) return;
              const isActive = activeSchools.find(
                (active) => active.id === tag.id
              );
              return (
                <div
                  className={`entry${isActive ? " active" : ""}`}
                  key={`${tag.id}-${index}`}
                  onClick={() => {
                    handleSelect(tag);
                  }}
                >
                  <span>
                    {tag.name} {isActive && <RiCheckLine />}
                  </span>
                </div>
              );
            })}
            {hasMore && (
              <div className="loader" ref={ref}>
                <BeatLoader color="#7dbce9" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DropdownSearchSchools;
