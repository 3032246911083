import IBasicResponse from "../../models/general/IBasicResponse";
import IData from "../../models/general/IData";
import IName from "../../models/general/IName";
import { ServiceResponse } from "../../models/serviceResponses/serviceResponse";
import { IIntern } from "../../models_NEW_STRUCTURE/general/IIntern";
import { IInternshipResponse } from "../../models_NEW_STRUCTURE/ServiceResponses/Internship/IInternshipResponse";
import { Delete, get, post, put } from "../../services/ServicesBase";

// ####### ROUTES #######
const STUDENT_PROFILE = "students/profile";
const INTERN_DELETE_PROFILE_IMAGE = "students/Profile/image";
const PUT_INTERN_PROFILE_NAME = "students/Profile/name";
const POST_INTERN_PROFILE_UPDATE_SKILLS = "students/Profile/updateskills";
const POST_INTERN_PROFILE_UPDATE_PERSONAL = "students/Profile/updatepersonal";
const STUDENT_DELETE_ACCOUNT = "students/Profile/deleteaccount"

// ========================================  GET METHODS  ========================================
// GET  /students/Profile
export const getStudentProfile = async (signal?: AbortSignal) => {
  const res = await get<ServiceResponse<IIntern>>(STUDENT_PROFILE, {
    signal: signal,
  });
  return res.data;
};

// GET  /students/Profile
export const getStudentProfileTest = async (signal?: AbortSignal) => {
  const res = await get<ServiceResponse<string>>(STUDENT_PROFILE, {
    signal: signal,
  });
  return res.data;
};

// GET  /students/Profile/getstudentbyid/:id
export const getStudentProfileById = async (id: string) => {
  const route = `students/Profile/getstudentbyid/${id}`;
  const res = await get<ServiceResponse<IIntern>>(route);
  return res.data;
};

// GET  /students/Profile/getstudentbyuserid/:id
export const getStudentProfileByUserId = async (id: string) => {
  const route = `students/Profile/getstudentbyuserid/${id}`;
  const res = await get<ServiceResponse<IIntern>>(route);
  return res.data;
};

// ========================================  PUT METHODS  ========================================
// PUT  /students/Profile/name
export const putInternProfileName = async (
  data: IName,
  signal?: AbortSignal
) => {
  const res = await put<ServiceResponse<IIntern>>(
    PUT_INTERN_PROFILE_NAME,
    data,
    {
      signal: signal,
    }
  );
  return res.data;
};

// ========================================  POST METHODS  ========================================
// POST /student/Profile/updateskills
interface ITitleAndSkills {
  title: string;
  skills: IData[];
}
export const postInternProfileUpdateTitleAndSkills = async (
  data: ITitleAndSkills,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IIntern>>(
    POST_INTERN_PROFILE_UPDATE_SKILLS,
    data,
    {
      signal: signal,
    }
  );
  return res.data;
};

// POST /student/Profile/updatepersonal
interface IOneLinerAndAbout {
  oneLiner: string;
  about?: string;
}
export const postInternProfileUpdateOneLinerAndAbout = async (
  data: IOneLinerAndAbout,
  signal?: AbortSignal
) => {
  const res = await post<ServiceResponse<IIntern>>(
    POST_INTERN_PROFILE_UPDATE_PERSONAL,
    data,
    {
      signal: signal,
    }
  );
  return res.data;
};
// ========================================  DELETE METHODS  ========================================
// DELETE  /students/Profile/image
export const deleteInternProfileImage = async (signal?: AbortSignal) => {
  const res = await Delete<ServiceResponse<IBasicResponse>>(
    INTERN_DELETE_PROFILE_IMAGE,
    {
      signal: signal,
    }
  );
  return res.data;
};

export const deleteStudentAccount = async (signal?: AbortSignal) => {
  const res = await Delete<ServiceResponse<IBasicResponse>>(
    STUDENT_DELETE_ACCOUNT,
    {
      signal: signal,
    }    
  );
return res.data;
}
