// import { triggerAsyncId } from "async_hooks";
import axios, { AxiosRequestConfig } from "axios";

axios.defaults.headers = {
  "content-type": "application/json",
  "Access-Control-Allow-Origin": "https://localhost:3000",
};
axios.defaults.withCredentials = true;
const ZIGG_URL = "https://embed-api-eu-west-1.ziggeo.com/v1/applications/";
const REACT_TEST = "https://rapidapi.com"

// ADDED THE ABILITY TO ATTACH AXIOS_REQUEST_CONFIG TO EACH FETCH METHODs

export const post = async <T>(
  route: string,
  data: any,
  options?: AxiosRequestConfig
) => {
  return await axios.post<T>(
    `${process.env.REACT_APP_BASE_URL + route}`,
    data,
    options && options
  );
};

export const put = async <T>(
  route: string,
  data?: any,
  options?: AxiosRequestConfig
) => {
  return await axios.put<T>(
    `${process.env.REACT_APP_BASE_URL + route}`,
    data,
    options && options
  );
};

export const get = async <T>(route: string, options?: AxiosRequestConfig) => {
  return await axios.get<T>(
    `${process.env.REACT_APP_BASE_URL + route}`,
    options && options
  );
};

export const Delete = async <T>(
  route: string,
  options?: AxiosRequestConfig
) => {
  return await axios.delete<T>(
    `${process.env.REACT_APP_BASE_URL + route}`,
    options && options
  );
};

export const DeleteFromZiggeo = async <T>(
  route: string,
  options?: AxiosRequestConfig
) => {
  return await axios.delete<T>(`${ZIGG_URL + route}`, options && options);
};

export const post2 = async <T>(
  route: string,
  data: any,
  options?: AxiosRequestConfig
) => {
  return await axios.post<T>(
    `${REACT_TEST + route}`,
    data,
    options && options
  );
};

