import Stripe from "stripe";

const apiKey = process.env.REACT_APP_STRIPE_API_KEY;
const config: Stripe.StripeConfig = {
  apiVersion: '2022-11-15', // Specify the desired Stripe API version
};
export const stripe = new Stripe(apiKey!, config);
export async function getCustomersByEmail(email: string): Promise<Stripe.Customer[]> {
  const customers = await stripe.customers.list({
    email: email,
    limit: 1, // Adjust the limit as per your requirements
  });
  return customers.data;
}

export async function getCompletedSessionsByCustomerId(customerId: string) {
  try {
    // Retrieve the customer using the customer ID
    const customer = await stripe.customers.retrieve(customerId);

    // Get all the Checkout Sessions for the customer
    const checkoutSessions = await stripe.checkout.sessions.list({
      customer: customer.id,
      limit: 2 // Increase or decrease the limit based on your needs
    });

    // Filter the sessions to only get the completed ones
    const completedSessions = checkoutSessions.data.filter(
      (session) => session.payment_status === 'paid'
    );

    // Return the completed sessions
    return completedSessions;
  } catch (error) {
    console.error('Error retrieving completed sessions:', error);
    throw error; // Re-throw the error to handle it elsewhere
  }
}










