import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiCheckLine } from "react-icons/ri";

import "./ChangePassword.scss";
import useScreenSize from "../../../hooks/useScreenSize/useScreenSize";
import { IChangePassword } from "../../../models/ChangePassword";
import {
  resetPassword,
  sendChangePassword,
} from "../../../services/account.service";

export const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [msg, setMsg] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const search = useLocation().search;
  const navigate = useNavigate();
  const { breakpoints } = useScreenSize();

  useEffect(() => {
    if (token !== "") return;

    const t = new URLSearchParams(search).get("token");
    const e = new URLSearchParams(search).get("email");
    if (t && e) {
      setToken(t);
      setEmail(e);
    }
  }, [token, setToken, search]);

  const sendChangePasswordClick = async () => {
    if (newPassword !== newPasswordAgain) {
      setMsg("Lösenorden matchar inte");
      return;
    }

    let data: IChangePassword = {
      oldPassword,
      newPassword,
      newPasswordAgain,
    };

    let response = await sendChangePassword(data);

    if (response.success) {
      setMsg(response.data);
      navigate("/login");
    } else {
      setMsg(response.error);
    }
  };

  const resetPasswordClick = async () => {
    if (newPassword !== newPasswordAgain) {
      setMsg("Lösenorden matchar inte");
      return;
    }
    let response = await resetPassword({ email, token, password: newPassword });

    if (response.success) {
      setMsg(response.data);
      navigate("/login");
    } else {
      setMsg(response.error);
    }
  };

  return (
    <>
      {token.length === 0 ? (
        <div className="change-password-container">
          {!breakpoints.IS_MOBILE ? (
            <div className="blue-div">
              {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
                <h1>Ändra lösenord</h1>
              ) : (
                <>{null}</>
              )}
            </div>
          ) : (
            <>{null}</>
          )}

          <div className="change-password-form">
            {!breakpoints.IS_LAPTOP ? (
              <h1 className="mobile-heading">Ändra lösenord</h1>
            ) : (
              <>{null}</>
            )}

            <label htmlFor="oldPassword">Gamla lösenordet</label>
            <input
              type="password"
              id="oldPassword"
              placeholder="Gamla lösenord"
              value={oldPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setOldPassword(e.target.value);
              }}
            />
            <label htmlFor="newPassword">Nytt lösenord</label>
            <input
              type="password"
              id="newPassword"
              placeholder="Nytt lösenord"
              value={newPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setNewPassword(e.target.value);
              }}
            />
            <label htmlFor="newPasswordAgain">Nya lösenordet igen</label>
            <input
              type="password"
              id="newPasswordAgain"
              placeholder="Nya lösenordet igen"
              value={newPasswordAgain}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setNewPasswordAgain(e.target.value);
              }}
            />
            <button type="button" onClick={sendChangePasswordClick}>
              Byt lösenord
              <RiCheckLine className="icon" />
            </button>
            <div>{msg}</div>
          </div>
        </div>
      ) : (
        <div className="change-password-container">
          {!breakpoints.IS_MOBILE ? (
            <div className="blue-div">
              {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
                <h1>Ändra lösenord</h1>
              ) : (
                <>{null}</>
              )}
            </div>
          ) : (
            <>{null}</>
          )}
          <div className="change-password-form">
            {breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL ? (
              <h1 className="mobile-heading">Ändra lösenord</h1>
            ) : (
              <>{null}</>
            )}
            <label htmlFor="newPassword">Nytt lösenord</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setNewPassword(e.target.value);
              }}
            />
            <label htmlFor="newPasswordAgain">Nya lösenordet igen</label>
            <input
              type="password"
              id="newPasswordAgain"
              value={newPasswordAgain}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setNewPasswordAgain(e.target.value);
              }}
            />
            <button type="button" onClick={resetPasswordClick}>
              Byt lösenord
            </button>
            <div>{msg}</div>
          </div>
        </div>
      )}
    </>
  );
};
