import React from "react";
import { Link } from "react-router-dom";
import { FaUserLock } from "react-icons/fa";
import "./noAccess.scss";

const NoAccess = () => {
  return (
    <div className="no-access-container">
      <div className="container">
        <div className="btn-div">
          <Link className="no-access-btn" to="/login">
            Logga in
          </Link>
          <Link className="no-access-btn" to="/">
            Startsidan
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
