import React, {
  useState,
  createContext,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { IContextValue, IUser } from "../models/users/IUser";
import { IUserDetails } from "../models/users/IUserDetails";
import { ServiceResponse } from "../models/serviceResponses/serviceResponse";
import { getCompanyProfile } from "../services_NEW_STRUCTURE/Profile/CompanyProfile.service";
import { get } from "../services/ServicesBase";
import { ICompany } from "../models_NEW_STRUCTURE/general/ICompany";
import { BLANK_ICOMPANY } from "../constants/blankData/company/blankCompany";
import { addCompanySubscription } from "../services/company.service";
import {
  getCompletedSessionsByCustomerId,
  getCustomersByEmail,
} from "../services/companies/stripe.service";

export const UserContext: React.Context<IContextValue> =
  createContext<IContextValue>({
    user: {
      id: "",
      username: "",
      loggedIn: false,
      role: "",
      hasUnreadMessages: false,
    },
    saveLoggedInUser: () => {},
    logout: (success: boolean) => {},
    getLoggedIn: () => {},
    isAdmin: (): Promise<boolean> => {
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    },
    loading: true,
    updateReadMessages: (hasUnreadMessages: boolean) => {},
    companySubscription: false,
    getCompanySubscription: () => {},
  });

interface IUserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider = (props: IUserContextProviderProps) => {
  const [user, setUser] = useState<IUser>({
    username: "",
    id: "",
    loggedIn: false,
    role: "",
    hasUnreadMessages: false,
  });
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [companySubscription, setCompanySubscription] = useState<
    boolean | null
  >(null);
  const [companyProfileData, setCompanyProfileData] =
    useState<ICompany>(BLANK_ICOMPANY);
  // useEffect(() => {
  //   console.log(user.role);
  // }, [user.role]);

  const fetchCompanyData = async () => {
    try {
      const res = await getCompanyProfile();
      if (res.success) {
        setCompanyProfileData(res.data);
        setCompanySubscription(res.data.isSubscription);
      } else {
      }
    } catch (error) {
      if (error as Error) return;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (user.role === "Company") {
      fetchCompanyData();
    }
  }, [user.role, companySubscription]);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const subscribe = async () => {
    if (companyProfileData.id != -1 && !isSubscribed) {
      try {
        const res = await addCompanySubscription(
          companyProfileData.id.toString()
        );
        if (res.success) {
          //setOpen(false);
          setIsSubscribed(true);
        } else {
          //setError(res.error);
        }
      } catch (error) {
        console.error((error as Error).message);
      }
    }
  };

  if (
    user.role === "Company" &&
    !companyProfileData.isSubscription &&
    user.id !== ""
  ) {
    const email = user.username;
    getCustomersByEmail(email)
      .then((customers) => {
        if (customers?.length > 0) {
          const customerId = customers[0].id;
          getCompletedSessionsByCustomerId(customerId)
            .then((completedSessions) => {
              const paymentStatus = completedSessions[0]?.payment_status;
              if (paymentStatus === "paid") {
                subscribe();
              }
            })
            .catch((error) =>
              console.error("Error retrieving completed sessions:", error)
            );
        } else {
          // console.log("");
        }
      })
      .catch((error) => console.error("Error retrieving customers:", error));
  }

  const logout = (success: boolean) => {
    if (success) {
      setUser({
        username: "",
        id: "",
        loggedIn: false,
        role: undefined,
        hasUnreadMessages: false,
      });
    } else return;
  };

  const isAdmin = async (token: string): Promise<boolean> => {
    if (token !== null) {
      let route = "authentication/getuser";
      let res = await get<ServiceResponse<IUserDetails>>(route);
      return new Promise<boolean>((resolve, reject) => {
        if (res.data.data.type === "Admin") {
          resolve(true);
        }
        reject(false);
      });
    } else {
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    }
  };

  const saveLoggedInUser = useCallback(
    async (
      email: string,

      role: string,
      id: string,
      hasUnreadMessages: boolean
    ) => {
      setUser({
        ...user,
        username: email,
        loggedIn: true,
        role: role,
        id: id,
        hasUnreadMessages: hasUnreadMessages,
      });
    },
    [user]
  );

  const getLoggedIn = useCallback(async () => {
    try {
      let route = "Authentication/getuser";
      let res = await get<ServiceResponse<IUserDetails>>(route);

      if (res.data.success === true) {
        if (!res.data.data) {
          setUser({
            ...user,
            username: "",
            id: "",
            loggedIn: false,
            role: "",
          });
        } else {
          saveLoggedInUser(
            res.data.data.email,
            res.data.data.type,
            res.data.data.id,
            res.data.data.hasUnreadMessages
          );
        }
      }

      setLoading(false);
    } catch (error) {
      console.error((error as Error).message);
      setUser({
        ...user,
        username: "",
        id: "",
        loggedIn: false,
        role: "",
      });
      setLoading(false);
    }

    setInitialLoad(true);
  }, [saveLoggedInUser, user]);

  const updateReadMessages = (hasUnreadMessages: boolean) => {
    setUser({ ...user, hasUnreadMessages: hasUnreadMessages });
  };

  useEffect(() => {
    if (!initialLoad) {
      getLoggedIn();
    }
  }, [getLoggedIn, initialLoad]);

  return (
    <UserContext.Provider
      value={{
        user,
        saveLoggedInUser,
        logout,
        getLoggedIn,
        isAdmin,
        loading,
        updateReadMessages,
        companySubscription,
        getCompanySubscription: fetchCompanyData, // Add this line
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
