import useScreenSize from "../../../hooks/useScreenSize/useScreenSize";

// import {
//   RiSearchLine,
//   RiArrowRightLine,
//   RiArrowLeftLine,
// } from "react-icons/ri";

import "./unsubscribe.scss";

export const Unsubscribe = () => {
  const { breakpoints } = useScreenSize();

  return (
    <div className="unsubscribe-container">
      {!breakpoints.IS_MOBILE ? (
        <div className="blue-div">
          {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
            <h1>Avsluta</h1>
          ) : (
            <>{null}</>
          )}
        </div>
      ) : (
        <>{null}</>
      )}
      <div className="content-container">
        {breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL ? (
          <h1>Avsluta</h1>
        ) : (
          <>{null}</>
        )}
        <div className="unsubscribe-text">
          <h2>Tråkigt att du väljer att avsluta!</h2>
          <p>
            Du är alltid välkommen tillbaka. Om du har feedback om din upplevlse
            kan du kontaka oss via info@hopen.se.
          </p>
          <div>
            <button>Avsluta prenumeration</button>
            <button>Behåll prenumeration</button>
          </div>
        </div>
      </div>
    </div>
  );
};
