import { Socket, io } from "socket.io-client";
import {
  IConversationResponse,
  IMessage,
} from "../models/messaging/IConversationResponse";
import { ServiceResponse } from "../models/serviceResponses/serviceResponse";

export interface IMessagingContext {
  socket: Socket | null;
  messages: IMessage[];
  conversations: IConversationResponse[];
  selectedConversation: IConversationResponse | undefined;
  hasUnreadMessages: boolean;
  loading: boolean;
}

export interface IMessageActionType {
  type: MessageReducerType;
  payload: any;
}

export enum MessageReducerType {
  CREATE_SOCKET,
  GOT_MESSAGES,
  GOT_CONVERSATIONS,
  GOT_CONVERSATION,
  CREATED_CONVERSATION,
  READ_CONVERSATION,
  LOADED,
}

export const MessagingReducer = (
  state: IMessagingContext,
  action: IMessageActionType
) => {
  switch (action.type) {
    case MessageReducerType.CREATE_SOCKET: {
      if (!state.socket) {
        let key = process.env.REACT_APP_SECRET_KEY;
        if (key === undefined) key = "";
        let url = process.env.REACT_APP_WS_URL;

        const s = io(url ?? "", {
          reconnectionAttempts: 3,
          reconnectionDelay: 100000,
          rejectUnauthorized: false,
          autoConnect: false,
          forceNew: true,
          extraHeaders: {
            secret_key: key,
            user: action.payload,
          },
        });

        s.on("connect", () => {
          //   console.log("Connected to socket");
        });

        s.on("connect_error", (err) => {
          console.log(err);
        });

        s.on("connect_failed", (err) => {
          console.log(err);
          console.log("disconnecting socket...");
          s.disconnect();
        });

        s.on("disconnect", (reason) => {
          console.log("socket disconnected.");
          console.log(reason);
        });

        s.connect();

        return { ...state, socket: s };
      }
      break;
    }

    case MessageReducerType.GOT_MESSAGES: {
      const messages: IMessage[] = action.payload;

      return { ...state, messages };
    }

    case MessageReducerType.GOT_CONVERSATIONS: {
      const conversations: IConversationResponse[] = action.payload;

      if (conversations.length > 0) {
        let lastConversation = conversations[conversations.length - 1];

        let convDate = new Date(lastConversation.startDate);

        if (new Date().getTime() - convDate.getTime() < 10000) {
          conversations.splice(conversations.length - 1, 1);

          if (conversations.length > 0) {
            return {
              ...state,
              conversations: [lastConversation, ...conversations],
              loading: false,
            };
          }

          return {
            ...state,
            conversations: [lastConversation, ...conversations],
            loading: false,
          };
        }

        return { ...state, conversations: conversations, loading: false };
      }

      break;
    }

    case MessageReducerType.GOT_CONVERSATION: {
      const conversation: IConversationResponse = action.payload || undefined;

      return { ...state, selectedConversation: conversation };
    }

    case MessageReducerType.CREATED_CONVERSATION: {
      return {
        ...state,
        conversations: [action.payload, ...state.conversations],
        selectedConversation: action.payload,
      };
    }

    case MessageReducerType.READ_CONVERSATION: {
      const cs = state.conversations.map((c) => {
        if (c.id === action.payload) {
          c.hasUnreadMessages = false;
        }

        return c;
      });

      return { ...state, conversation: cs };
    }

    case MessageReducerType.LOADED: {
      return { ...state, loading: action.payload };
    }

    default:
      break;
  }

  return state;
};
