import React, { useCallback, useEffect, useState } from "react";
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";
import useScreenSize from "../../hooks/useScreenSize/useScreenSize";
import "./pagination.scss";

interface IProps {
  numberOfPages: number;
  nextPage: () => void;
  previousPage: () => void;
  pageNumber: number;
}

const Pagination = ({
  numberOfPages,
  nextPage,
  previousPage,
  pageNumber,
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState<string>(`${pageNumber}`);
  const [html, setHtml] = useState<JSX.Element[]>([]);

  const { breakpoints } = useScreenSize();

  const onFocus = () => {
    setIsOpen(true);
  };
  const onBlur = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (pageNumber.toString() !== page) {
      setPage(`${pageNumber}`);
    } else return;
  }, [page, pageNumber]);

  const generateHTML = useCallback(() => {
    let pageNumberHTML: JSX.Element[] = [];

    for (let i = 1; i <= numberOfPages; i++) {
      pageNumberHTML.push(
        <option
          key={i}
          className="select--option-btn"
          onMouseDown={(e: React.MouseEvent) => onClickPage(e, i)}
        >
          {i}
        </option>
      );
    }

    return pageNumberHTML;
  }, [numberOfPages]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const res = generateHTML();
      setHtml(res);
    }

    return () => {
      isMounted = false;
    };
  }, [numberOfPages, generateHTML]);

  const updatePage = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setPage(e.target.value);
    } catch (error) {
      return;
    }
  };

  const onClickPage = (e: React.MouseEvent, number: number) => {
    e.preventDefault();
    setPage(`${number}`);
    setIsOpen(false);
  };

  return (
    <div className="pagination" id="pagination-container">
      <button className="page-button" onClick={previousPage}>
        <IoChevronBackSharp className="icon-back" />
        {breakpoints.IS_LAPTOP ? "Tillbaka" : null}
      </button>
      <p>Sida</p>
      <div className="select-container">
        <div className="select">
          {isOpen ? <div className="numbers-container">{html}</div> : null}
          <p>{page}</p>
        </div>
      </div>

      <p>av</p>
      <p>{numberOfPages}</p>
      <button className="page-button" onClick={nextPage}>
        {breakpoints.IS_LAPTOP ? "Nästa" : null}
        <IoChevronForwardSharp className="icon-next" />
      </button>
    </div>
  );
};

export default Pagination;
