import { useState } from "react";
import Spinner, { ISpinnerSize } from "../spinner/Spinner";
import "./image.scss";

interface ISize {
  width: string;
  height: string;
}
interface IProps {
  imageUrl: string | undefined;
  fallbackImageUrl: string;
  imageSize: ISize;
  spinnerSize: ISpinnerSize;
  borderRadius?: string;
  alt?: string;
}

const Image = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      className="preview"
      style={{
        width: props.imageSize.width,
        height: props.imageSize.height,
      }}
    >
      <img
        alt={props.alt}
        src={props.imageUrl}
        onLoad={() => {
          setIsLoading(false);
        }}
        style={{
          borderRadius: props.borderRadius ? props.borderRadius : "1rem",
        }}
      />

      <div
        className={`loading-overlay ${isLoading ? "show" : "hide"}`}
        style={{
          borderRadius: props.borderRadius ? props.borderRadius : "1rem",
        }}
      >
        <Spinner size={props.spinnerSize} />
      </div>
    </div>
  );
};

export default Image;
