import React, { useCallback, useEffect, useState, useRef } from "react";
import "./dropdownSingle.scss";
// import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import useOutsideClick from "../../hooks/useOutsideClick/useOutsideClick";
import { areListsEqual } from "../../helpers/ListValidations";


interface IData {
  name: string;
  id: number;
}

interface IProps {
  data: IData[];
  placeholder: string;
  updateState: (value: IData) => void;
  dropdownDisabled?: boolean;
  preSetValue?: IData;
  clear?: boolean;
  setClear?(value: boolean): void;
  //Nour
  onSelect?: (entries: IData[]) => void;
  startValue?: IData[];
}

//! A drop down input component with searching and filtering built in.

//! This is a multi select dropdown that returns a value of IData.

//! The component has a 100% width of its container.

//! Takes in a function that recives a value of type IData[].

//? Example:
// const handleFunction(value : IData) => {
//     value = the selected options in the dropdown as a IData object.

//     setMyStateInParentComponent(value);      (--Lifting state up--)
// }

//! The component takes in a placeholder value of type string.

//! Takes in an array of data that can be filtered with search.

//! A data object needs to have a name field of type string and a id of type number. See interface IData.

function DropdownSingle({
  data,
  placeholder,
  updateState,
  dropdownDisabled,
  preSetValue,
  clear,
  setClear,
  startValue
}: IProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<IData[]>(data);
  const [addedItem, setAddedItem] = useState<IData | null>();
  const [inputActive, setInputActive] = useState<boolean>(false);

  ///added
  const [inputText, setInputText] = useState("");

  const onFocus = () => setInputActive(true);
  const onBlur = () => {
    setInputActive(false);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    handleFilterData(e.target.value);

  };

  const handleFilterData = useCallback(
    (searchTerm: string) => {
      let newFilter = data.filter((value) => {
        return value.name.toLowerCase().includes(searchTerm.toLowerCase());
      });

      setFilteredData(newFilter);

      if (addedItem !== null && addedItem !== undefined) {
        if (addedItem.name.length > 0) {
          for (let j = 0; j < newFilter.length; j++) {
            if (addedItem?.id === newFilter[j].id) {
              newFilter.splice(j, 1);
            }
          }
        }
      } else {
        if (filteredData !== newFilter) {
          setFilteredData(newFilter);
        } else {
          return;
        }
      }
    },
    [addedItem, filteredData, data]
  );

  const addItem = (value: IData, e: React.MouseEvent) => {
    e.preventDefault();
    setAddedItem(value);
    updateState(value);
    setSearchTerm(value.name);
    setInputActive(false);
  };
  // useEffect(() => {
  //   if (preSetValue === undefined) return;
  //   let isMounted = true;
  //   let doOnce = 0;
  //   if (isMounted) {
  //     if (preSetValue && doOnce === 0) {
  //       setAddedItem(preSetValue);
  //     } else return;
  //   } else return;

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [preSetValue]);

  // useEffect(() => {
  //   if (clear === undefined || setClear === undefined) return;

  //   if (clear) {
  //     setAddedItem({
  //       name: "",
  //       id: -1,
  //     });
  //     setClear(false);
  //     setSearchTerm("");
  //   }
  // }, [clear, setClear]);

  useEffect(() => {
    if (addedItem !== undefined && addedItem !== null) {
      if (!filteredData.includes(addedItem)) {
        handleFilterData(searchTerm);
      }
    }
  }, [searchTerm, addedItem, handleFilterData, filteredData]);

  useEffect(() => {
    if (addedItem) {
      if (addedItem.name !== searchTerm) {
        setAddedItem(null);
        updateState({ name: "", id: 0 });
        return;
      }
    }
  }, [addedItem, updateState, searchTerm]);

  /////...........................My try to save
  const [activeEntries, setActiveEntries] = useState<IData[]>(
    startValue ? startValue : []
  );

  useEffect(() => {
    if (!areListsEqual(data, activeEntries))
      setActiveEntries(startValue ? startValue : []);
  }, [data, startValue]);

  useEffect(() => {
    if (startValue) setActiveEntries(startValue);
  }, [startValue]);

  useEffect(() => {
    if (activeEntries[0])
      setSearchTerm(activeEntries[0].name ? activeEntries[0].name : "");
  }, [activeEntries]);


  return (
    <div
      className={`dropdown-single ${inputActive
        ? "container-border-color-black"
        : "container-border-color-gray"
        }`}
    >
      <section className="input-section">
        <input
          type="text"
          placeholder={placeholder}
          className="input"
          onChange={handleFilter}
          value={searchTerm}///.....chande from searchTerm
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={dropdownDisabled}
        />
        <div className="icon-container">
          {inputActive ? (
            <RiArrowUpSLine
              onClick={() => setInputActive(false)}
              className="up"
            />
          ) : (
            <RiArrowDownSLine onClick={() => onFocus()} className="down" />
          )}
        </div>
      </section>

      {inputActive && (
        <ul className="dataResult">
          {filteredData.map((value) => {
            return (
              <li
                className="item"
                key={value.id}
                onMouseDown={(e: React.MouseEvent) => addItem(value, e)}
                tabIndex={0}

              >
                {value.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default DropdownSingle;
