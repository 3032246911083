import { useEffect, useState } from "react";
import "./popupCSubscription.scss";
import { RiCloseLine } from "react-icons/ri";
import axios, { AxiosResponse } from "axios";
import Stripe from "stripe";

interface IProps {
  noAlertFunction: () => void;
  updateStripeClicked: (value: boolean) => void; // Callback function to update isStripeClicked
}

const StandardizedPopupSubscriptionWithStripe = ({
  noAlertFunction,
  updateStripeClicked, // Add the updateStripeClicked prop
}: IProps) => {
  interface ApiResponse {
    status: string;
  }
  const apiKey = process.env.REACT_APP_STRIPE_API_KEY;
  const stripePayLink = process.env.REACT_APP_STRIPE_PAY_LINK;
  const config: Stripe.StripeConfig = {
    apiVersion: "2022-11-15", // Specify the desired Stripe API version
  };
  const stripe = new Stripe(apiKey!, config);
  const [isStripeClicked, setIsStripeClicked] = useState(false); // Add the isStripeClicked state variable

  const handleStripeClick = () => {
    setIsStripeClicked(true); // Update the isStripeClicked state when the Stripe button is clicked
    updateStripeClicked(true); // Call the updateStripeClicked callback function
    noAlertFunction();
    window.location.href = stripePayLink!;
  };

  return (
    <div id="subscription-popup-alert">
      <div className="alert-box">
        <button className="close-btn" onClick={noAlertFunction}>
          <RiCloseLine className="close-btn__icon" />
        </button>
        {/* <h1 className="alert-title">{title}</h1> */}
        <h1 className="alert-title">Hej!</h1>
        <p className="alert-message">För att komma åt denna funktion behöver du Hopen Premium.</p>
        <p className="alert-message">Kom igång direkt genom att starta premium - för 495kr/mån får du full tillgång. Läs mer och betala på Stripe.</p>
        <a
          className="payment-link"
          onClick={handleStripeClick} // Call handleStripeClick when the Stripe button is clicked
        >
          Hopen Premium
        </a>
      </div>
    </div>
  );
};

export default StandardizedPopupSubscriptionWithStripe;
