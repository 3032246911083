import { regions } from "../../../datalists/regions";
import {
  ICompanyDetails,
  ICompanyPaymentInfo,
} from "../../../models/ICompanyDetails";
import {
  ICompanyResponse,
  IProsessions,
} from "../../../models/serviceResponses/ICompanyResponse";
import { ICompany } from "../../../models_NEW_STRUCTURE/general/ICompany";
import { BLANK_ICONTACT } from "../general/blankGeneral";

export const BLANK_ICOMPANY: ICompany = {
  id: -1,
  name: "",
  url: "",
  region: "",
  regions: [],
  location: "",
  description: "",
  imageUrl: "",
  organisationNumber: "",
  adress: "",
  zip: "",
  city: "",
  title: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  internships: [],
  isFavourite: false,
  allowMessagesFromStudents: false,
  isSubscription: false,
  //industryTags:[]
  applications: [],
};

// !!!!!!!!!!!!!!!!!!!! OLD STUFF !!!!!!!!!!!!!!!!!!!!
// #####################################################################
// ICompanyDetails
export const BLANK_ICOMPANY_DETAILS: ICompanyDetails = {
  name: "",
  description: "",
  url: "",
  location: "",
  region: "",
  regions: [],
  isSubscription: false,
};
export const BLANK_ICOMPANY_PAYMENT_INFO: ICompanyPaymentInfo = {
  organisationnumber: "",
  adress: "",
  zip: "",
  city: "",
};
// #####################################################################

// #####################################################################
// ICompanyResponse
export const BLANK_IPROSESSIONS: IProsessions = {
  name: "",
};
export const BLANK_COMPANY_RESPONSE: ICompanyResponse = {
  id: -1,
  contact: BLANK_ICONTACT,
  description: "",
  longDescription: "",
  imageUrl: "",
  name: "",
  professions: [],
  tags: [],
  url: "",
  users: [],
};
// #####################################################################
