import React from "react";
import { PuffLoader } from "react-spinners";
import "./loader.scss";

const Loader = () => {
  return (
    <>
      <div className="loader-container">
        <div className="loader">
          <PuffLoader size={150} loading={true} color="#fff" />
          <p className="text">Sidan laddas...</p>
        </div>
      </div>
    </>
  );
};

export default Loader;
