import { useCallback, useEffect, useState } from "react";
import { BLANK_IINTERNSHIP } from "../../../constants/blankData/intern/blankIntern";
import { IInternship } from "../../../models/IInternshipInterface";
import "./schoolUserSearchStudents.scss";
import { RiCloseCircleLine } from "react-icons/ri";
import { RiArrowRightLine } from "react-icons/ri";
import { RiFilter2Line } from "react-icons/ri";
import { RiCloseFill } from "react-icons/ri";
import {
  getAllSchools,
  getSchoolUserStudents,
  postSchoolInfo,
} from "../../../services/school/school.service";

// import Dropdown from "../../../components/general/dropdown/Dropdown";
import IData from "../../../models/general/IData";
import SchoolStudentSearchCard from "../../../components/school/cards/SchoolStudentSearchCard/SchoolStudentSearchCard";
import useScreenSize from "../../../hooks/useScreenSize/useScreenSize";
import { IInternPeriods } from "../../../models/serviceResponses/ISearchResponse";
import StudentSearchResultCard from "../../../components/cards/studentSearchResultCard/StudentSearchResultCard";

import { ISchools } from "../../../models/ISchoolInterface";
import DropdownSearch from "../../../components/general/dropdownSearch/DropdownSearch";
import SchoolSearch from "../../../components/schoolsSearch/SchoolSearch";
import { BLANK_IDATA } from "../../../constants/blankData/general/blankGeneral";
import { getStudentAccountInfo } from "../../../services/students/getStudentData";
import { IUserDetails } from "../../../models/users/IUserDetails";
import useGetSchools from "../../../components/schoolsSearch/useGetSchools";

import { isCancel } from "../../../helpers/AbortHelpers";
import {
  getSchoolById,
  getSchools,
  getSchoolsSearch,
} from "../../../services_NEW_STRUCTURE/School.service";
import Pagination from "../../../components/pagination/Pagination";
import { IPageData } from "../../../models/componentProps/IPageDataInterface";
import { StudentResponse } from "../../../models/intern/StudentResponse";
import DropdownSearchSchools from "../../../components/general/dropdownSearchSchools/DropdownSearchSchools";
import { IIntern } from "../../../models_NEW_STRUCTURE/general/IIntern";
import Spinner from "../../../components/general/spinner/Spinner";
import { useLocation } from "react-router";

export interface IStudent {
  about: string;
  educations: string[];
  id: number;
  isFavourite: boolean;
  oneLiner: string;
  tags: IData[];
  title: string;
  user: IUserDetails;
}
const BlankStudentResponse = {
  about: "",
  educations: [],
  id: null,
  isFavourite: false,
  oneLiner: "",
  tags: [{ name: "", id: null }],
  title: "",
  user: {
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    imageUrl: "",
    type: "",
    lastLoggedIn: "",
  },
};

let abortController: AbortController;

export function SchoolUserSearchStudents() {
  //  first render 20 student results from our db n list those in cards
  const [freeSearchTerm, setFreeSearchTerm] = useState<string>(""); //from free word input
  const [schoolTerm, setSchoolTerm] = useState<string>("");
  const [showFilters, setShowFilters] = useState(false); // show filter menu on toggle
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { loading, schools, hasMore, error } = useGetSchools(
    schoolTerm,
    pageNumber
  );
  const [searching, setSearching] = useState(true);

  const [activeSchool, setActiveSchool] = useState<IData[]>([BLANK_IDATA]);

  const [pageData, setPageData] = useState<IPageData>({
    firstPage: "",
    lastPage: "",
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    nextPage: null,
    totalPages: 0,
    totalRecords: 0,
  });

  const [chosenSchool, setChosenSchool] = useState<IData>(BLANK_IDATA); // school dropdown
  const baseResultsRoute =
    "school/studentsearch/search?PageNumber=1&PageSize=10&q=";

  const [students, setStudents] = useState<IIntern[]>([]);

  const getStudentsData = async (route: string) => {
    setSearching(true);
    try {
      const res = await getSchoolUserStudents(route);

      if (res.data.success) {
        const studentData = res.data.data.data;
        setStudents(studentData);
        let pageData = {
          firstPage: res.data.data.firstPage,
          lastPage: res.data.data.lastPage,
          nextPage: res.data.data.nextPage,
          previousPage: res.data.data.previousPage,
          pageNumber: res.data.data.pageNumber,
          pageSize: res.data.data.pageSize,
          totalRecords: res.data.data.totalRecords,
          totalPages: res.data.data.totalPages,
        };
        setPageData(pageData);
        // console.log("students are:", res.data.data.data);
        // added a scroll to top
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setUrlToRoute(route);
    } catch (error) {
      console.log((error as Error).message);
    }

    setSearching(false);
  };

  useEffect(() => {
    if (checkIfUrlHasSearch()) {
      searchFromUrlRoute();
    } else {
      getStudentsData(baseResultsRoute);
    }
  }, []);

  const { breakpoints } = useScreenSize();

  const sendSearchFilters = () => {
    let schoolIdQ = "";
    if (activeSchool.length > 0) {
      schoolIdQ =
        activeSchool[0].id !== -1 ? `&SchoolId=${activeSchool[0].id}` : "";
    }
    const freeSearchQ = freeSearchTerm !== "" ? `&q=${freeSearchTerm}` : "";

    let route = `school/studentsearch/search?PageNumber=1&PageSize=20${freeSearchQ}${schoolIdQ}`;
    getStudentsData(route);
  };

  const resetFilters = () => {
    setActiveSchool([]);
    setChosenSchool(BLANK_IDATA);
    setFreeSearchTerm("");
    getStudentsData(baseResultsRoute);
  };

  const location = useLocation();

  const makeSearchRouteFromUrl = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const routeString = searchParams.get("Filter");
      let newRoute = baseResultsRoute;
      if (routeString) {
        newRoute = decodeURIComponent(routeString);
      }

      const routeSearchParams = new URLSearchParams(newRoute);
      setFreeSearchTerm(routeSearchParams.get("q") || "");
      const schoolId = routeSearchParams.get("SchoolId");
      if (schoolId) {
        const resp = await getSchoolById(schoolId);
        if (resp.success) {
          setActiveSchool([resp.data]);
        }
      }

      return newRoute;
    } catch (error) {
      return baseResultsRoute;
    }
  };

  const searchFromUrlRoute = async () => {
    const route = await makeSearchRouteFromUrl();
    getStudentsData(route);
  };

  const checkIfUrlHasSearch = () => {
    if (location && location.search.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const setUrlToRoute = (route: string) => {
    window.history.replaceState(
      null,
      "Hopen - Sökning",
      "/schooluser/searchstudents/search?" +
        "Filter=" +
        encodeURIComponent(route)
    );
  };

  return (
    <div className="school-user-search-students-wrapper">
      <div className="school-user-search-students">
        {(breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL) &&
          showFilters && (
            <section className="filter-students">
              <button className="close" onClick={() => setShowFilters(false)}>
                <RiCloseFill />
              </button>
              <form className="filter-students-form">
                <div className="free-search">
                  <label className="title-label">Fri sökning</label>
                  <input
                    type="text"
                    placeholder="Sök i fritext"
                    className="search-input"
                    value={freeSearchTerm}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFreeSearchTerm(e.target.value);
                    }}
                  />
                </div>

                <div className="school-dropdown">
                  <label className="title-label">Sök på skola</label>
                  {/* replace with dropdownSearch */}
                  {/* <DropdownSearch
                  id={"school-search"}
                  name={"school-search"}
                  placeholder={"Skola:"}
                  data={schools}
                  onSelect={(school) => {
                    setChosenSchool(school);
                  }}
                  onSearch={(text) => {
                    setSchoolTerm(text);
                  }}
                  openOnFocus={true}
                /> */}
                  <DropdownSearchSchools
                    id="dropdown-search-schools"
                    activeSchools={activeSchool}
                    onSchoolsChange={function (schools: IData[]): void {
                      setActiveSchool(schools);
                    }}
                    singleChoice
                  />
                </div>

                <div className="btn-container">
                  <button
                    className="search-save-btn"
                    type="button"
                    onClick={sendSearchFilters}
                  >
                    Visa resultat
                    <RiArrowRightLine className="right-icon" />
                  </button>
                  <button
                    className="search-reset-btn"
                    type="button"
                    onClick={resetFilters}
                  >
                    Rensa filter <RiCloseCircleLine className="right-icon" />
                  </button>
                </div>
              </form>
            </section>
          )}

        {(breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL) &&
          showFilters === false && (
            <div
              className="margin font"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              Filtera sökning
              <RiFilter2Line />
            </div>
          )}
        {(breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP) && (
          <section className="filter-students ">
            <form className="filter-students-form">
              <div className="free-search">
                <label className="title-label">Fri sökning</label>
                <input
                  type="text"
                  placeholder="Sök i fritext"
                  className="search-input"
                  value={freeSearchTerm}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFreeSearchTerm(e.target.value);
                  }}
                />
                <p>Sök efter t.ex studenters namn</p>
              </div>

              <div className="school-dropdown">
                <label className="title-label">Sök på skola</label>
                {/* <DropdownSearch
                  id={"school-search"}
                  name={"school-search"}
                  placeholder={"Skola:"}
                  data={schools}
                  onSelect={(school) => {
                    setChosenSchool(school);
                  }}
                  onSearch={(text) => {
                    setSchoolTerm(text);
                  }}
                  openOnFocus={true}
                /> */}
                <DropdownSearchSchools
                  id="dropdown-search-schools"
                  activeSchools={activeSchool}
                  onSchoolsChange={function (schools: IData[]): void {
                    setActiveSchool(schools);
                  }}
                  singleChoice
                />
                <p>Sök efter skolor som finns på Hopen</p>
              </div>
              <div className="btn-container">
                <button
                  className="search-save-btn"
                  type="button"
                  onClick={sendSearchFilters}
                >
                  Visa resultat
                  <RiArrowRightLine className="right-icon" />
                </button>
                <button
                  className="search-reset-btn"
                  type="button"
                  onClick={resetFilters}
                >
                  Rensa filter <RiCloseCircleLine className="right-icon" />
                </button>
              </div>
            </form>
          </section>
        )}
        <div className="student-results">
          {(breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL) &&
            (searching ? (
              <Spinner className="icon" size="large"></Spinner>
            ) : (
              <>
                <section className="student-results-cards">
                  {students.length === 0 ? (
                    <p className="zero-results">
                      Tyvärr hittade vi inga studenter som matchade din sökning.
                    </p>
                  ) : (
                    students.map((student) => (
                      <StudentSearchResultCard
                        intern={student}
                        key={student.id}
                      ></StudentSearchResultCard>
                      // <SchoolStudentSearchCard student={student} key={student.id} />
                    ))
                  )}
                </section>
                <div className="pagination">
                  {students.length !== 0 && (
                    <Pagination
                      numberOfPages={pageData.totalPages}
                      pageNumber={pageData.pageNumber}
                      nextPage={() => {
                        getStudentsData(
                          pageData.nextPage ? pageData.nextPage : ""
                        );
                      }}
                      previousPage={() => {
                        getStudentsData(
                          pageData.previousPage ? pageData.previousPage : ""
                        );
                      }}
                    />
                  )}
                </div>
              </>
            ))}
          {(breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP) &&
            (searching ? (
              <Spinner className="icon" size="large"></Spinner>
            ) : (
              <>
                <div className="pagination">
                  {students.length !== 0 && (
                    <Pagination
                      numberOfPages={pageData.totalPages}
                      pageNumber={pageData.pageNumber}
                      nextPage={() => {
                        getStudentsData(
                          pageData.nextPage ? pageData.nextPage : ""
                        );
                      }}
                      previousPage={() => {
                        getStudentsData(
                          pageData.previousPage ? pageData.previousPage : ""
                        );
                      }}
                    />
                  )}
                </div>
                <section className="student-results-cards">
                  {students.length === 0 ? (
                    <p className="zero-results">
                      Tyvärr hittade vi inga studenter som matchade din sökning.
                    </p>
                  ) : (
                    students.map((student) => (
                      <StudentSearchResultCard
                        intern={student}
                        key={student.id}
                      ></StudentSearchResultCard>
                      // <SchoolStudentSearchCard student={student} key={student.id} />
                    ))
                  )}
                </section>
                <div className="pagination">
                  {students.length !== 0 && (
                    <Pagination
                      numberOfPages={pageData.totalPages}
                      pageNumber={pageData.pageNumber}
                      nextPage={() => {
                        getStudentsData(
                          pageData.nextPage ? pageData.nextPage : ""
                        );
                      }}
                      previousPage={() => {
                        getStudentsData(
                          pageData.previousPage ? pageData.previousPage : ""
                        );
                      }}
                    />
                  )}
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
}
