import { FormEvent, useCallback, useEffect, useState } from "react";
import { RiCloseLine, RiPencilLine } from "react-icons/ri";
import { IMG_CONFIG } from "../../../../config";
import { ISchoolUser } from "../../../../models_NEW_STRUCTURE/general/ISchoolUser";
import IFile from "../../../../models_NEW_STRUCTURE/general/IFile";
import useObjectState from "../../../../hooks/useObjectState/useObjectState";
import ImageEditable from "../../../general/imageEditable/ImageEditable";
import useScreenSize from "../../../../hooks/useScreenSize/useScreenSize";
import { postFilesUploadImage } from "../../../../services_NEW_STRUCTURE/Files.service";
import { isCancel } from "../../../../helpers/AbortHelpers";
import { deleteInternProfileImage } from "../../../../services_NEW_STRUCTURE/Profile/InternProfile.service";
import { putSchoolProfileUpdateById } from "../../../../services_NEW_STRUCTURE/Profile/SchoolProfile.service";
import InputWithValidationMsg from "../../../general/inputWithValidationMsg/InputWithValidationMsg";
import IData from "../../../../models_NEW_STRUCTURE/general/IData";
import { isName, isPhone } from "../../../../helpers/StringValidations";
import "./schoolUserProfileForm.scss";
import { getSchools } from "../../../../services_NEW_STRUCTURE/School.service";
import { BLANK_IDATA } from "../../../../constants/blankData/general/blankGeneral";
import Dropdown from "../../../general/dropdown/Dropdown";
import SchoolSearch from "../../../schoolsSearch/SchoolSearch";
import DropdownSingle from "../../../dropdownSingleSelect/DropdownSingle";
import { ISchools } from "../../../../models/ISchoolInterface";

interface IInputValidity {
  firstname: boolean;
  lastname: boolean;
  phoneNumber: boolean;
  school: boolean;
}

interface IInputStates {
  firstname: string;
  lastname: string;
  phoneNumber: string;
}

const BLANK_INPUT_VALIDITY: IInputValidity = {
  firstname: true,
  lastname: true,
  phoneNumber: true,
  school: true,
};

// COMPONENT PROPS
interface IProps {
  schoolUserInfo: ISchoolUser;
  fetchSchoolUserData: () => void;
}

let fetchSchoolsController: AbortController;
let profileImageController: AbortController;
let formSubmitController: AbortController;

// COMPONENT
const SchoolUserProfileForm = ({
  schoolUserInfo,
  fetchSchoolUserData,
}: IProps) => {
  // BREAKPOINTS FROM HOOK
  const { breakpoints } = useScreenSize();

  // STATE FOR LOADING IMAGE
  const [isProfileImageLoading, setIsProfileImageLoading] =
    useState<boolean>(false);

  // STATE FOR FORM INPUTS DISABLED vs USABLE
  const [formDisabled, setFormDisabled] = useState<boolean>(true);

  // INPUT FIELDS
  const [inputFields, setInputFields] = useObjectState<IInputStates>({
    firstname: schoolUserInfo.user.firstname,
    lastname: schoolUserInfo.user.lastname,
    phoneNumber: schoolUserInfo.user.phoneNumber,
  });

  // VALIDATION STATES
  const [validStates, setValidStates] =
    useObjectState<IInputValidity>(BLANK_INPUT_VALIDITY);

  const [liveValidate, setLiveValidate] = useState(false);

  // STATES FOR SCHOOL
  const [schools, setSchools] = useState<IData[]>([]);

  const [chosenSchool, setChosenSchool] = useState<IData>(BLANK_IDATA);

  // STATE FOR ERROR HANDLING
  const [error, setError] = useState<string>("");

  // CHECK TO SEE IF INPUT IS VALID
  const isFormValid = (): boolean => {
    let isValid = true;
    const validity: IInputValidity = { ...BLANK_INPUT_VALIDITY };

    // FIRST NAME CHECK
    if (!isName(inputFields.firstname)) {
      isValid = false;
      validity.firstname = false;
    }

    // LAST NAME CHECK
    if (!isName(inputFields.lastname)) {
      isValid = false;
      validity.lastname = false;
    }

    // PHONE CHECK
    if (!isPhone(inputFields.phoneNumber)) {
      isValid = false;
      validity.phoneNumber = false;
    }

    // SCHOOL CHECK
    if (chosenSchool.id <= 0) {
      isValid = false;
      validity.school = false;
    }

    // UPDATE VALIDITY STATES
    setValidStates({
      ...validity,
    });

    // IF isValid === false, WE HAVE INVALID INPUTS
    if (!isValid) {
      setLiveValidate(true);

      return false;
    } else return true;
  };

  // FETCH SCHOOLS
  const fetchSchools = useCallback(async () => {
    try {
      const res = await getSchools();
      if (res.success) {
        setSchools(res.data);
      } else {
        setError(res.error);
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
    return;
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (schools.length === 0) {
        fetchSchools();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [schools.length, fetchSchools]);

  // FUNCTION FOR GETTING SCHOOLS IN DROPDOWN ON CLICK
  const handleFetchSchoolsOnClick = async () => {
    if (fetchSchoolsController) fetchSchoolsController.abort();
    fetchSchoolsController = new AbortController();
    if (schools.length === 0) {
      fetchSchools();
    }
    return () => {
      if (fetchSchoolsController) fetchSchoolsController.abort();
    };
  };

  // USEEFFECT FOR SETTING ORIGINAL STARTVALUE IN FORM
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setChosenSchool(schoolUserInfo.school);
      setInputFields({
        firstname: schoolUserInfo.user.firstname,
        lastname: schoolUserInfo.user.lastname,
        phoneNumber: schoolUserInfo.user.phoneNumber,
      });
    }

    return () => {
      if (fetchSchoolsController) {
        fetchSchoolsController.abort();
      }
      isMounted = false;
    };
  }, [schoolUserInfo]);

  // UPDATES PROFILE IMAGE
  const updateProfileImg = async (file: IFile) => {
    if (profileImageController) profileImageController.abort();
    profileImageController = new AbortController();
    try {
      const res = await postFilesUploadImage(
        file,
        profileImageController.signal
      );
      if (res.success) {
        fetchSchoolUserData();
      } else {
        setError(res.error);
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
  };

  // DELETES PROFILE IMAGE (USES THE SAME ENDPOINT AS FOR STUDENT FOR NOW - ONLY TEMP)
  const deleteProfileImg = async () => {
    if (profileImageController) profileImageController.abort();
    profileImageController = new AbortController();
    try {
      const res = await deleteInternProfileImage(profileImageController.signal);

      if (res.success) {
        fetchSchoolUserData();
      } else {
        setError(res.error);
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      setError((error as Error).message);
    }
  };

  // HELPER FUNCTION
  const convertToISchool = (inputs: IInputStates) => {
    const data: ISchoolUser = {
      ...schoolUserInfo,
      user: {
        ...schoolUserInfo.user,
        firstname: inputs.firstname,
        lastname: inputs.lastname,
        phoneNumber: inputs.phoneNumber,
      },
    };
    return data;
  };

  // FORM SUBMIT
  const formSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (formSubmitController) formSubmitController.abort();
    formSubmitController = new AbortController();

    try {
      if (isFormValid()) {
        const data: ISchoolUser = convertToISchool(inputFields);
        data.school = chosenSchool;
        const res = await putSchoolProfileUpdateById(
          schoolUserInfo.id,
          data,
          formSubmitController.signal
        );

        if (res.success) {
          fetchSchoolUserData();
          setFormDisabled(true);
        } else {
          console.error(res.error);
        }
      }
    } catch (error) {
      if (isCancel(error as Error)) return;
      console.error((error as Error).message);
    }
  };

  interface SchoolUserInforProps {
    phone: string;
    school: ISchools;
  }

  const [educatorData, setEducatorData] = useState<SchoolUserInforProps>({
    phone: "",
    school: {
      id: 0,
      name: "",
    },
  });

  const updateEducationState = (value: IData) => {
    setEducatorData({
      ...educatorData,
      school: { name: value.name, id: value.id },
    });
  };

 
 

  return (
    <div className="school-user-form-wrapper">
      {/* // INFO FORM STARTS */}
      <div className="form-background">
        <form className="school-user-profile-form" onSubmit={formSubmit}>
          {/* PROFILE IMAGE */}
          <div className="profile-img-container">
            <ImageEditable
              imageUrl={schoolUserInfo.user.imageUrl}
              fallbackImageUrl={IMG_CONFIG.AVATAR_LOGOS.SCHOOLUSER_DEFAULT}
              onSave={(file) => {
                updateProfileImg(file);
              }}
              onDelete={(file) => {
                deleteProfileImg();
              }}
              isLoadingState={isProfileImageLoading}
              setLoadingState={(isLoading) => {
                setIsProfileImageLoading(isLoading);
              }}
              imageSize={{
                width:
                  breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL
                    ? "11.9rem"
                    : "17rem",
                height:
                  breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL
                    ? "11.9rem"
                    : "17rem",
              }}
            />
            {/* TOGGLE BUTTON FOR ENABELING INPUT FIELDS */}
            <button
              type="button"
              className="edit-btn"
              onClick={() => {
                if (formDisabled) {
                  setFormDisabled(false);
                } else {
                  if (!formDisabled) {
                    setFormDisabled(true);
                  }
                }
                setFormDisabled(!formDisabled);
                handleFetchSchoolsOnClick();
              }}
            >
              {formDisabled ? (
                <RiPencilLine className="icon" />
              ) : (
                <RiCloseLine className="icon" />
              )}
            </button>
          </div>
          {/* FIRSTNAME */}
          <InputWithValidationMsg
            id="school-user-first-name"
            label="Förnamn"
            placeholder="Förnamn"
            type="text"
            value={inputFields.firstname}
            disabled={formDisabled}
            onChange={(e) => {
              setInputFields({
                firstname: e.target.value,
              });
            }}
            isValidState={validStates.firstname}
            validationMsg="Du måste ange ett giltigt förnamn."
          />
          {/* LASTNAME */}
          <InputWithValidationMsg
            id="school-user-last-name"
            label="Efternamn"
            placeholder="Efternamn"
            type="text"
            value={inputFields.lastname}
            disabled={formDisabled}
            onChange={(e) => {
              setInputFields({
                lastname: e.target.value,
              });
            }}
            isValidState={validStates.lastname}
            validationMsg="Du måste ange ett giltigt efternamn."
          />
          {/* SCHOOL DROPWDOWN */}
          <div
            className={`school-dropdown-container ${validStates.school ? "" : "invalid"
              }`}
          >
            <label htmlFor="school">Skola</label>
            <DropdownSingle
              placeholder="Sök skolan"
              data={schools.sort((a, b) => {
                if (a.name === b.name) return a.name < b.name ? -1 : 1
                else return a.name < b.name ? -1 : 1
              })}
              // updateState={updateEducationState}
              updateState={(school) => {
                setChosenSchool(school);
              }}
              dropdownDisabled={formDisabled}
              startValue={[chosenSchool]}
            />
            {/* <Dropdown
              id="school-search"
              name="school-search"
              placeholder="Sök skola"
              data={
                schools.sort((a, b) => {
                  if (a.name === b.name) return a.name < b.name ? -1 : 1
                  else return a.name < b.name ? -1 : 1
                })}
              // schools.filter((school) =>
                // school.name && school.name.length > 0 ? school : null
                
              startValue={[chosenSchool]}
              menuPosition="relative"
              onSelect={(school) => {
                setChosenSchool(school[0]);
              }}
              disabled={formDisabled}
            /> */}
            {!validStates.school ? (
              <p className="validation-message">Välj en skola.</p>
            ) : null}
          </div>
          {/* REGION DROPDOWN - COMMENTED OUT FOR NOW*/}
          {/* <div className="regions-dropdown-container">
            <label>Plats</label>
            <Dropdown
              id="regions-dropdown"
              data={regions}
              startValue={chosenRegion}
              placeholder={regions[0].name}
              menuPosition="relative"
              onSelect={(region) => {
                setChosenRegion(region);
              }}
              disabled={formDisabled}
            />
          </div> */}
          {/* PHONE */}
          <div className="phone-input-container">
            <label htmlFor="school-user-phone">Telefon</label>
            <p className="phone-not-displayed-text">
              (Syns ej för andra användare)
            </p>
            <InputWithValidationMsg
              id="school-user-phone"
              placeholder="Lägg till ett telefonnummer"
              type="tel"
              value={inputFields.phoneNumber}
              disabled={formDisabled}
              onChange={(e) => {
                setInputFields({
                  phoneNumber: e.target.value,
                });
              }}
              isValidState={validStates.phoneNumber}
              validationMsg="Du måste ange ett giltigt efternamn."
            />
          </div>
          {formDisabled ? null : (
            <button className="submit-btn" type="submit">
              Spara
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default SchoolUserProfileForm;
