import IPaginatedData from "../models/general/IPaginatedData";
import IData from "../models_NEW_STRUCTURE/general/IData";
import ServiceResponse from "../models_NEW_STRUCTURE/ServiceResponses/ServiceResponse";
import { get } from "../services/ServicesBase";

const SCHOOL_ROUTE = "School";
// ========================================  GET METHODS  ========================================
//GET   /School         // gets all schools
export const getSchools = async (
  signal?: AbortSignal
): Promise<ServiceResponse<IData[]>> => {
  const res = await get<ServiceResponse<IData[]>>(SCHOOL_ROUTE, { signal });
  return res.data;
};

//GET   /School/{id}
export const getSchoolById = async (
  id: string,
  signal?: AbortSignal
): Promise<ServiceResponse<IData>> => {
  const res = await get<ServiceResponse<IData>>(`${SCHOOL_ROUTE}/${id}`, {
    signal,
  });
  return res.data;
};

//GET   /School/search //
export const getSchoolsSearch = async (
  pageNumber: number,
  pageSize: number,
  q: string,
  signal?: AbortSignal
): Promise<ServiceResponse<IPaginatedData<IData[]>>> => {
  const res = await get<ServiceResponse<IPaginatedData<IData[]>>>(
    `${SCHOOL_ROUTE}/search?pageNumber=${pageNumber}&pageSize=${pageSize}&q=${q}`,
    {
      signal,
    }
  );
  return res.data;
};
