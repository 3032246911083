import { useEffect, useState } from "react";
import { RiAddLine, RiCloseLine, RiPencilLine } from "react-icons/ri";
import { BLANK_IIMAGE } from "../../../constants/blankData/general/blankGeneral";
import useObjectState from "../../../hooks/useObjectState/useObjectState";
import IFile from "../../../models_NEW_STRUCTURE/general/IFile";
import Spinner from "../spinner/Spinner";
import "./imageEditable.scss";

interface ISize {
  width: string;
  height: string;
}
interface IProps {
  imageUrl: string | undefined;
  fallbackImageUrl: string;
  imageSize: ISize;
  onSave: (data: IFile) => void;
  onDelete: (data: IFile) => void;
  isLoadingState: boolean;
  setLoadingState: (isLoading: boolean) => void;

  // OPTIONAL
  name?: string;
  className?: string;
  editClass?: string;
  normalClass?: string;
  editImageSize?: ISize;
}

const ImageEditable = (props: IProps) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [previewUrl, setPreviewUrl] = useState(
    props.imageUrl ? props.imageUrl : props.fallbackImageUrl
  );
  const [isEdit, setIsEdit] = useState(false);

  const [needSave, setNeedSave] = useState(false);
  const [fileData, setFileData] = useObjectState<IFile>(BLANK_IIMAGE);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      if (parseInt((file.size / 1024 / 1024).toFixed(4)) >= 2) {
        return setErrorMsg("Fil storleken är för stor.");
      }
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        return setErrorMsg("Otillåtet filformat.");
      }
      if (file && file.type.substring(0, 5) === "image") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setNeedSave(true);
          setPreviewUrl(reader.result as string);
          setFileData({
            name: file.name,
            file: reader.result as string,
            type: file.type,
          });
        };
        reader.readAsDataURL(file);
      } else {
        setFileData(BLANK_IIMAGE);
        setNeedSave(false);
        setPreviewUrl(props.fallbackImageUrl);
      }
    }
  };

  const handleClose = () => {
    if (needSave) {
      setNeedSave(false);
    }
    setPreviewUrl(previewUrl);
    setIsEdit(false);
  };

  const handleSave = () => {
    props.onSave(fileData);
    setIsEdit(false);
  };

  const handleDelete = () => {
    props.onDelete(fileData);
    setIsEdit(false);
  };

  // ON MOUNT, ON CHANGED IMAGE URL AND IF PROP IMAGE_URL HAS CHANGED
  // AND USER HAS NOT UPLOADED NEW IMAGE FILE, SHOW SPINNER.
  // TURNS OFF WHEN <img> TAG HAS LOADED SAID IMAGE.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      props.setLoadingState(true);
      if (previewUrl !== props.imageUrl)
        setPreviewUrl(props.imageUrl ? props.imageUrl : props.fallbackImageUrl);
    }
    return () => {
      isMounted = false;
    };
  }, [props.imageUrl]);

  return (
    <div
      className={`image-card-component ${
        isEdit
          ? `edit ${props.editClass ? props.editClass : ""}`
          : `normal ${props.normalClass ? props.normalClass : ""}`
      }`}
    >
      {isEdit && (
        <button className="close-button" onClick={handleClose}>
          <RiCloseLine />
        </button>
      )}
      <div
        className="preview"
        style={{
          width: props.imageSize.width,
          height: props.imageSize.height,
        }}
      >
        <img
          src={previewUrl}
          onLoad={() => {
            props.setLoadingState(false);
          }}
          onError={() => {
            setPreviewUrl(props.fallbackImageUrl);
          }}
        />

        <div
          className={`loading-overlay ${
            props.isLoadingState ? "show" : "hide"
          }`}
        >
          <Spinner size="large" />
        </div>
        {!isEdit && (
          <button
            className="edit-image-button"
            onClick={() => {
              setIsEdit(true);
            }}
          >
            <RiPencilLine />
          </button>
        )}
      </div>

      {isEdit && errorMsg.length > 0 && (
        <span className="error-msg">{errorMsg}</span>
      )}

      {isEdit && (
        <>
          <div className="choose-image-button">
            <label htmlFor="file-upload-profile">
              <input
                type="file"
                id="file-upload-profile"
                name={props.name}
                accept="image/jpeg, image/png, image/jpg"
                onChange={handleFileChange}
              />
              <span>
                Välj bild <RiAddLine className="add-icon" />
              </span>
            </label>
          </div>
          {previewUrl !== props.fallbackImageUrl && (
            <button className="delete-button" onClick={handleDelete}>
              Ta bort
            </button>
          )}
          <button className="save-button" onClick={handleSave}>
            Spara
          </button>
        </>
      )}
    </div>
  );
};

export default ImageEditable;
