import { createContext, Dispatch } from "react";
import {
  IMessageActionType,
  IMessagingContext,
} from "../reducers/messagingReducer";

export const MessageContext = createContext<IMessagingContext>({
  socket: null,
  conversations: [],
  messages: [],
  selectedConversation: undefined,
  hasUnreadMessages: false,
  loading: false,
});

export const MessageDispatchContext = createContext<
  Dispatch<IMessageActionType>
>(() => {});
