import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IPageData } from "../models/componentProps/IPageDataInterface";
import IData from "../models/general/IData";

export interface ILocation {
  office: boolean;
  hybrid: boolean;
}

export interface ITime {
  fullTime: boolean;
  partTime: boolean;
}
interface IFilterContextValue {
  filter: IFilter;
  region: IData[];
  skills: IData[];
  checkedTime: ITime;
  checkedLocation: ILocation;
  updateState: <T>(name: string, value: T) => void;
  updateEntireState: (value: IFilter) => void;
  updateSkills: (value: IData[]) => void;
  updateRegion: (value: IData[]) => void;
  updateTimeChecked: (value: IData, checked: boolean) => void;
  updateLocationChecked: (value: IData, checked: boolean) => void;
  clearFilters: () => void;
  pageData: IPageData;
  setPageData: Dispatch<SetStateAction<IPageData>>;
}

export const SearchFilterContext = createContext<IFilterContextValue>({
  filter: {
    searchTerm: "",
    start: "",
    end: "",
    type: "",
    location: [],
    pay: "",
    time: [],
  },
  region: [],
  skills: [],
  checkedTime: {
    fullTime: false,
    partTime: false,
  },
  checkedLocation: {
    office: false,
    hybrid: false,
  },
  updateState: () => {},
  updateEntireState: () => {},
  updateSkills: () => {},
  updateRegion: () => {},
  updateTimeChecked: () => {},
  updateLocationChecked: () => {},
  clearFilters: () => {},
  pageData: {
    firstPage: "",
    lastPage: "",
    nextPage: "",
    previousPage: "",
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
  },
  setPageData: () => {},
});

interface IFilterContextProviderProps {
  children: ReactNode;
}

interface IFilter {
  searchTerm: string;
  start: string;
  end: string;
  type: string;
  location: IData[];
  pay: string;
  time: IData[];
}

export const SearchFilterContextProvider = (
  props: IFilterContextProviderProps
) => {
  const [filter, setFilter] = useState<IFilter>({
    searchTerm: "",
    start: "",
    end: "",
    type: "",
    location: [],
    pay: "",
    time: [],
  });

  const [pageData, setPageData] = useState<IPageData>({
    firstPage: "",
    lastPage: "",
    nextPage: "",
    previousPage: "",
    pageNumber: 1,
    pageSize: 20,
    totalPages: 1,
    totalRecords: 1,
  });

  const [region, setRegion] = useState<IData[]>([]);
  const [skills, setSkills] = useState<IData[]>([]);
  const [checkedTime, setCheckedTime] = useState<ITime>({
    fullTime: false,
    partTime: false,
  });
  const [checkedLocation, setCheckedLocation] = useState<ILocation>({
    office: false,
    hybrid: false,
  });


  const updateTimeChecked = useCallback(
    (value: IData, checked: boolean) => {
      if (checked) {
        let tempArray = [];
        for (let i = 0; i < filter.time.length; i++) {
          if (filter.time[i].id !== value.id) {
            tempArray.push(filter.time[i]);
          }
        }
        setFilter({
          ...filter,
          time: tempArray,
        });
      } else {
        setFilter({
          ...filter,
          time: [...filter.time, value],
        });
      }

      if (value.name === "Heltid") {
        setCheckedTime({
          fullTime: !checkedTime.fullTime,
          partTime: checkedTime.partTime,
        });
      } else if (value.name === "Deltid") {
        setCheckedTime({
          fullTime: checkedTime.fullTime,
          partTime: !checkedTime.partTime,
        });
      }
    },
    [filter, checkedTime.fullTime, checkedTime.partTime]
  );

  const updateLocationChecked = useCallback(
    (value: IData, checked: boolean) => {
      if (checked) {
        let tempArray = [];
        for (let i = 0; i < filter.location.length; i++) {
          if (filter.location[i].id !== value.id) {
            tempArray.push(filter.location[i]);
          }
        }
        setFilter({
          ...filter,
          location: tempArray,
        });
      } else {
        setFilter({
          ...filter,
          location: [...filter.location, value],
        });
      }

      if (value.id === 1) {
        setCheckedLocation({
          office: !checkedLocation.office,
          hybrid: checkedLocation.hybrid,
        });
      } else if (value.id === 2) {
        setCheckedLocation({
          office: checkedLocation.office,
          hybrid: !checkedLocation.hybrid,
        });
      }
    },
    [filter, checkedLocation.office, checkedLocation.hybrid]
  );

  const updateSkills = useCallback((value: IData[]) => {
    setSkills(value);
  }, []);

  const updateRegion = useCallback((value: IData[]) => {
    setRegion(value);
  }, []);

  const updateEntireState = useCallback((value: IFilter) => {
    setFilter(value);
  }, []);

  const updateState = useCallback(
    <T,>(name: string, value: T) => {
      setFilter({
        ...filter,
        [name]: value,
      });
    },
    [filter]
  );

  const clearFilters = () => {
    setFilter({
      searchTerm: "",
      start: "",
      end: "",
      type: "",
      location: [],
      pay: "",
      time: [],
    });
    setRegion([]);
    setSkills([]);
    setCheckedLocation({
      office: false,
      hybrid: false,
    });
    setCheckedTime({
      fullTime: false,
      partTime: false,
    });
  };

  return (
    <SearchFilterContext.Provider
      value={{
        filter,
        updateState,
        updateEntireState,
        region,
        skills,
        updateSkills,
        updateRegion,
        checkedLocation,
        checkedTime,
        updateLocationChecked,
        updateTimeChecked,
        clearFilters,
        pageData,
        setPageData,
      }}
    >
      {props.children}
    </SearchFilterContext.Provider>
  );
};
