import { useEffect, useState } from "react";
import { RiArrowRightLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../../hooks/useScreenSize/useScreenSize";
import { getStudentInfoOnBookCompleted } from "../../../services/companies/book-complete.service";
import "./Success.scss";

export const Offer = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>("");
  const [bookedStudentDetails, setBookedStudentDetails] = useState({
    id: 0,
    name: "",
    internPeriod: {},
  });

  const {
    breakpoints: { IS_MOBILE, IS_TABLET_SMALL, IS_TABLET_BIG, IS_LAPTOP },
  } = useScreenSize();

  const navigate = useNavigate();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      let q = query.get("session_id");
      if (q) {
        setSessionId(q);
      }
    }

    if (query.get("canceled")) {
      setSuccess(false);
    }
  }, [sessionId]);

  useEffect(() => {
    let isMounted = true;
    let getOnce = 0;
    if (isMounted) {
      const getUserInfo = async () => {
        const res = await getStudentInfoOnBookCompleted(sessionId);

        if (res.success) {
          setBookedStudentDetails(res.data);
          getOnce++;
        }
      };

      if (sessionId.length > 0 && getOnce === 0) {
        getUserInfo();
      }
    }

    return () => {
      isMounted = false;
      getOnce++;
    };
  }, [sessionId]);

  const goToProfile = () => {
    if (bookedStudentDetails.id && bookedStudentDetails.id > 0) {
      navigate(`/student/page/${bookedStudentDetails.id}`);
    }
  };

  return (
    <div className="success-container">
      {!success ? (
        <>
          {!IS_MOBILE ? (
            <div className="blue-div">
              {IS_TABLET_BIG || IS_LAPTOP ? (
                <h1>Betalningen misslyckades eller avbröts</h1>
              ) : (
                <>{null}</>
              )}
            </div>
          ) : (
            <>{null}</>
          )}
          <div className="content-container">
            {IS_MOBILE || IS_TABLET_SMALL ? (
              <>
                <h1 className="mobilesize-heading">
                  Betalningen misslyckades eller avbröts
                </h1>
              </>
            ) : (
              <>{null}</>
            )}
            {IS_TABLET_BIG || IS_LAPTOP ? (
              <button className="back-btn">
                {/* <RiArrowLeftLine className="back-icon" /> Back to payment */}
              </button>
            ) : (
              <>{null}</>
            )}
            <h2>Du valde att avbryta din betalning</h2>
            <p>
              När du känner dig redo, gå gärna tillbaka och slutför den för att
              fortsätta ha tillgång till Hopen.
            </p>
          </div>
        </>
      ) : (
        <>
          {!IS_MOBILE ? (
            <div className="blue-div">
              {IS_TABLET_BIG || IS_LAPTOP ? (
                <h1>Praktikperiod bokad!</h1>
              ) : (
                <>{null}</>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="content-container">
            {IS_MOBILE || IS_TABLET_SMALL ? (
              <h1 className="mobilesize-heading">Praktikperiod bokad!</h1>
            ) : (
              <h2> Tack!</h2>
            )}
            {bookedStudentDetails.id && bookedStudentDetails.id > 0 ? (
              <>
                <p>
                  Du har nu bokat {bookedStudentDetails.name} för en
                  praktikperiod.
                </p>
                <p>Ett meddelande om bokningen har skickats till studenten.</p>
                <button className="redirect-link" onClick={goToProfile}>
                  Gå till talangens profil <RiArrowRightLine className="icon" />
                </button>
                <button
                  className="redirect-link"
                  onClick={() => navigate("/company/search")}
                >
                  Söka praktikanter <RiSearchLine className="icon" />
                </button>
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};
