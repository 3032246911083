import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { ServiceResponse } from "../../models/serviceResponses/serviceResponse";
import { IPageData } from "../../models/componentProps/IPageDataInterface";
import IData from "../../models/general/IData";

interface IResponse extends IPageData {
  data: IData[];
}

const useGetSchools = (q: string, PageNumber: number) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<IData[]>([]);
  const [error, setError] = useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(false);

  const calcResponse = (PageNumber: number, totalPages: number) => {
    setHasMore(totalPages > PageNumber ? true : false);
  };

  const settingSchools = useCallback(
    (data: IData[]) => {
      if (q === "") {
        setSchools([]);
        setError("");
        return;
      }
      if (data.length === 0) return;

      let s = schools.filter((school) => {
        return school.name.toLowerCase().includes(q.toLowerCase());
      });
      let d = data
        .filter((school) => {
          let exists = schools.find((schools) => schools.id === school.id);
          return exists ? false : true;
        })
        .map((school) => {
          return school;
        });

      setSchools([...s, ...d]);
    },
    [q, schools]
  );

  useEffect(() => {
    let isMounted = true;
    if (q === "") return setSchools([]);

    const cancelToken = axios.CancelToken.source();

    if (isMounted) {
      setLoading(true);
      const getSchools = async () => {
        try {
          const res = await axios.get<ServiceResponse<IResponse>>(
            `${process.env.REACT_APP_BASE_URL}School/search?q=${q}&PageSize=5&PageNumber=${PageNumber}`,
            {
              cancelToken: cancelToken.token,
            }
          );
          if (res.data.success) {
            setLoading(false);
            calcResponse(PageNumber, res.data.data.totalPages);
            settingSchools(res.data.data.data);
            setError("");
          } else if (res.data.error !== null) {
            setError(res.data.error);
            setSchools([]);
            setLoading(false);
          }
        } catch (error) {
          if (axios.isCancel(error)) return;
          setError((error as Error).message);
          setLoading(false);
        }
      };
      getSchools();
    }

    return () => {
      cancelToken.cancel();
      isMounted = false;
    };

    //settingSkills in dependency array casues infinite loop
  }, [q, PageNumber]);

  return {
    loading,
    schools,
    hasMore,
    error,
  };
};

export default useGetSchools;
