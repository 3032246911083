import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../services/logout.service";
import {
  RiSearchLine,
  RiArrowRightLine,
  //   RiArrowLeftLine,
  RiHeartLine,
} from "react-icons/ri";
import {
  checkPaymentStatus,
  //   createPortalSession,
  getSubscriptionFromUser,
} from "../../../services/payment.service";
import "./Success.scss";
import useScreenSize from "../../../hooks/useScreenSize/useScreenSize";

export const Success = () => {
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [userOnTrial, setUserOnTrial] = useState(false);

  const navigate = useNavigate();
  const { breakpoints } = useScreenSize();

  useEffect(() => {
    if (!success) {
      checkPaymentStatus().then((response) => {
        if (response.success) {
          if (response.data.needPaymentRegistration) {
            logOut().then(() => {
              navigate("/login");
            });
          }
        }
      });
    }
  }, [success, navigate]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      let q = query.get("session_id");
      if (q) setSessionId(q);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      const checkSubscription = async () => {
        const subscribed = await getSubscriptionFromUser();
        if (subscribed.success) {
          if (subscribed.data.subscriptionId === "Trial") {
            setUserOnTrial(true);
          }
        }
      };
      checkSubscription();
    }
  }, [sessionId]);

  const backToPayment = () => {
    navigate("/company/payment");
  };

  return (
    <div className="success-container">
      {!success ? (
        <>
          {!breakpoints.IS_MOBILE ? (
            <div className="blue-div">
              {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
                <h1></h1>
              ) : (
                <>{null}</>
              )}
            </div>
          ) : (
            <>{null}</>
          )}
          {!userOnTrial ? (
            <div className="content-container">
              <h2>Du valde att avbryta din betalning</h2>
              <p>
                Din prenumeration är avbruten och du kommer att bli utloggad
                från Hopen. Om du ångrar dig och vill hoppa på Hopen-tåget igen
                så är det bara att logga in och starta en prenumeration. Om du
                har några frågor kontakta oss gärna: info@hopen.se
              </p>
              <p>
                Hoppas vi ses snart igen! <RiHeartLine />
              </p>
            </div>
          ) : (
            <div className="content-container">
              <h2>Din registrering av prenumeration på Hopen blev avbruten</h2>
              <p>
                Om du vill börja prenumerera på vår superba tjänst senare så
                klickar du bara på Hantera prenumeration på din profilsida.
              </p>
              <p>
                Under tiden är ett hett tips att fortsätta lägga till
                information om ditt företag och tidsperioder för när ni kan ta
                emot en praktikant.
              </p>
              <button
                className="primary-btn"
                onClick={() => navigate("/contact")}
              >
                Lägg till information <RiArrowRightLine className="icon" />
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          {!breakpoints.IS_MOBILE ? (
            <div className="blue-div">
              {breakpoints.IS_TABLET_BIG || breakpoints.IS_LAPTOP ? (
                <h1>Välkommen till Hopen!</h1>
              ) : (
                <>{null}</>
              )}
            </div>
          ) : (
            <>{null}</>
          )}
          <div className="content-container">
            {breakpoints.IS_MOBILE || breakpoints.IS_TABLET_SMALL ? (
              <h1 className="mobilesize-heading">Välkommen till Hopen!</h1>
            ) : (
              <>{null}</>
            )}
            <h2>Ditt kort är nu registrerat</h2>
            <button
              onClick={() => navigate("/contact")}
              className="secondary-btn"
            >
              Fyll i företagsuppgifter <RiArrowRightLine className="icon" />
            </button>
            <button
              onClick={() => navigate("/company/profile")}
              className="secondary-btn"
            >
              Gå till profil <RiArrowRightLine className="icon" />
            </button>
            <button
              onClick={() => navigate("/company/internship")}
              className="secondary-btn"
            >
              Skapa praktikannons <RiArrowRightLine className="icon" />
            </button>
            <button
              onClick={() => navigate("/company/search")}
              className="secondary-btn"
            >
              Sök talanger <RiSearchLine className="icon" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
