import { IFavourite } from "./../models/IFavourite";
import { ServiceResponse } from "../models/serviceResponses/serviceResponse";
import { Delete, get, post, put } from "./ServicesBase";
import { AxiosRequestConfig } from "axios";

export const addAsFavourite = async (
  id: number,
  signal?: AbortSignal
): Promise<ServiceResponse<IFavourite>> => {
  const route = `Favourite/add/${id}`;
  const res = await post<ServiceResponse<IFavourite>>(route, id, {
    signal: signal,
  });
  return res.data;
};

export const removeAsFavourite = async (
  id: number,
  signal?: AbortSignal
): Promise<ServiceResponse<boolean>> => {
  const route = `favourite/remove/${id}`;
  const res = await Delete<ServiceResponse<boolean>>(route, { signal: signal });
  return res.data;
};

export const getMyFavourites = async (
  signal?: AbortSignal
): Promise<ServiceResponse<IFavourite[]>> => {
  const route = `favourite`;
  const res = await get<ServiceResponse<IFavourite[]>>(route, {
    signal: signal,
  });
  return res.data;
};

export const getStudentsFavoritingMyCompany = async (
  signal?: AxiosRequestConfig
) => {
  const route = "Favourite/bycompany";
  const res = await get<ServiceResponse<IFavourite[]>>(route, signal);
  return res.data;
};
