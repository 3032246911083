import { isObjectInList } from "./helpers/ListValidations";
import { isEmail, isPhone } from "./helpers/StringValidations";

export const IMG_CONFIG = {
  HOPEN_LOGOS: {
    BLUE_LOGO: "/img/logos/logoBlue.png",
    WHITE_LOGO: "/img/logos/logoWhite.png",
    LOGO: "/img/logos/Logo_colored.png",
    INTERNSHIPS_LOGO: "/img/logos/Logo_internships_colored.png"
  },
  AVATAR_LOGOS: {
    COMPANY_DEFAULT: "/img/logos/officeWorker.png",
    INTERN_DEFAULT: "/img/logos/studentLogo.png",
    SCHOOLUSER_DEFAULT: "/img/logos/schoolUserLogo.png",
  },
  CONTENT: {
    SUPER_TRUSTWORTHY_IMAGE_OF_JACOB_TO_EARN_MORE_MONEY: "/img/Jacob.png",
  },
};

export const NEW_GRAPHICS = {
  POPUP: "/img/pop-up_box.png",
  COMPANY_POPUP: "img/pop-up-company_box.png",
  BLANK_POPUP: "img/popupbox.png",
  BG_DESKTOP: "/img/backgrounds/background_desktop.png",
  BG_TABLET: "/img/backgrounds/background_tablet.png",
  BG_MOBILE: "/img/backgrounds/background_mobile.png",
  BG_REG_DESKTOP_1: "/img/backgrounds/background_desktop_registration_01.png",
  BG_REG_DESKTOP_2: "/img/backgrounds/background_desktop_registration_02.png",
  BG_REG_DESKTOP_3: "/img/backgrounds/background_desktop_registration_03.png",
  BG_REG_DESKTOP_4: "/img/backgrounds/background_desktop_registration_04.png",
  BG_REG_TABLET_1: "/img/backgrounds/background_tablet_registration_01.png",
  BG_REG_TABLET_2: "/img/backgrounds/background_tablet_registration_02.png",
  BG_REG_TABLET_3: "/img/backgrounds/background_tablet_registration_03.png",
  BG_REG_TABLET_4: "/img/backgrounds/background_tablet_registration_04.png",
  STUDENT_BADGE: "/img/student_badge.png",
  STUDENT_BADGE_2: "/img/student_badge2.png"
}

export const BREAKPOINTS = {
  MOBILE: 599, // Between this as 768 (and below 599)
  TABLET_SMALL: 768, // Between this and 1024
  TABLET_BIG: 1024, // Between this and 1200
  LAPTOP: 1200, // Larger than 1200
};

export const STRING_VALIDATION = {
  IS_EMAIL: isEmail,
  IS_PHONE: isPhone,
};

export const LIST_VALIDATION = {
  IS_OBJECT_IN_LIST: isObjectInList,
};
